import firstpage from "@/assets/menu/fristpage.png";
import newbuild from "@/assets/menu/newbuild.png";
import sendrecord from "@/assets/menu/sendrecord.png";
import companyinfo from "@/assets/menu/companyinfo.png";
import firstpageclcik from "@/assets/menu/fristpage-click.png";
import newbuildclcik from "@/assets/menu/newbuild-click.png";
import sendrecordclcik from "@/assets/menu/sendrecord-click.png";
import companyinfoclcik from "@/assets/menu/companyinfo-click.png";

export default [
  {
    label: "首页",
    isShow: true,
    icon: firstpage,
    path: "/salary/fristpage",
    icon1: firstpageclcik,
    hover:false,
    active: false
  },
  {
    label: "新建工资条",
    isShow: true,
    icon: newbuild,
    path: "/salary/newbuild",
    icon1: newbuildclcik,
    hover:false,
    active: false
  },
  {
    label: "发送记录",
    isShow: true,
    icon: sendrecord,
    path: "/salary/sendrecord",
    icon1: sendrecordclcik,
    hover:false,
    active: false
  },
  // {
  //   label: "数据分析",
  //   isShow: true,
  //   icon: datafenxi,
  // },
  {
    label: "公司信息",
    isShow: true,
    icon: companyinfo,
    path: "/salary/companyinfo",
    icon1: companyinfoclcik,
    hover:false,
    active: false
  }
];
