<template>
  <div class="content-upload">
    <div class="salary-tabs">
      <div class="left-tab" @click="changeTab(true)">
        <div :class="isClickLeft === true ? 'tab-tip-click' : 'tab-tip'">
          自定义工资表
        </div>
      </div>
      <div class="right-tab" @click="changeTab(false)">
        <div :class="isClickLeft === false ? 'tab-tip-click' : 'tab-tip'">
          选择已录入柠檬云软件的工资数据
        </div>
      </div>
      <div class="tab-devider" :class="{ 'is-right': !isClickLeft }"></div>
    </div>

    <div class="devider"></div>
    <salary-selfdefined
      v-if="isClickLeft"
      :salaryTypeList="salaryTypeList"
      @close="salaryTypeListget"
      @checkMethod="checkMethod"
    ></salary-selfdefined>
    <salary-existcompany
      v-if="!isClickLeft"
      :salaryTypeList="salaryTypeList"
      :jzAsData="jzAsData"
      @close="salaryTypeListget"
      @checkMethod="checkMethod"
      :source="source"
    ></salary-existcompany>
  </div>
</template>

<script>
export default {
  name: "salaryupload",
  components: {},
  props: {
    
  },
  data() {
    return {
      isClickLeft: true,
      salaryTypeconfirmVisible: false,
      uploadVisible: false,
      activeName: "first",
      salaryTypeList: [],
      jzAsData: {
        asid: 0,
        period: 0,
      },
      source: '',
    };
  },
  created() {
    // jz：从记账免费版进入，选中财务软件数据
    // pro：记账专业版
    // erp：业财
    const { asid, type, period } = this.$route.query;
    if (type && asid && period && (type == "jz" || type == "pro" || type == "erp")) {
      this.jzAsData = {
        asid: Number(asid),
        period: Number(period),
      };
      history.replaceState(null, null, "/salary/newbuild");
      this.isClickLeft = false;
      this.source = type;
    }

    /**
     * 获取工资类型列表
     */
    this.salaryTypeListget();
  },
  beforeMount() {},
  mounted() {
    if (
      this.source === "erp" ||
      this.source === "jz" ||
      this.source === "pro"
    ) {
      this.changeTab(false);
    }
  },
  methods: {
    changeTab(event) {
      this.isClickLeft = event;
      if (event) {
        this.salaryTypeListget();
      } else {
        this.executeSecondTab();
      }
    },
    generatePromise(flag) {
      return new Promise((resolve, reject) => {
        this.$axios.erpApi
          .hasYcService(flag)
          .then((data) => {
            //判断逻辑：serviceId有值，且type的值为vip
            resolve({ success: true, data });
          })
          .catch((error) => {
            resolve({ success: false, error });
          });
      });
    },
    //点击“选择已录入柠檬云软件的工资数据”tab
    executeSecondTab() {
      Promise.all([this.generatePromise("erp"), this.generatePromise("acc")])
        .then((data) => {
          let [erpData, accData] = data;
          this.handleBuyResult(erpData, "$setBuyErp");
          this.handleBuyResult(accData, "$setBuyAcc");
        })
        .finally(() => {
          this.salaryTypeListget();
        });
    },
    //处理是否购买返回结果
    handleBuyResult(ret, commitKey) {
      if (ret.success) {
        let result = ret.data;
        if (result.serviceId && result.type == "vip") {
          this.$store.commit(commitKey, true);
        } else {
          this.$store.commit(commitKey, false);
        }
      } else {
        this.$store.commit(commitKey, true);
      }
    },
    //  弹窗
    setSalaryType() {
      this.salaryTypeconfirmVisible = true;
    },
    upload() {
      this.uploadVisible = true;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    /**
     * 获取工资类型列表
     */
    salaryTypeListget() {
      this.$axios.gzApi.SalaryTypeGet(this.$store.getters.store_comanyId).then(
        (res) => {
          if (res.data.data) {
            this.salaryTypeList = res.data.data;
          }
        },
        (err) => {
          console.log("获取工资类型列表err===" + err);
        }
      );
    },
    checkMethod(excelDetail, index) {
      this.$emit("checkMethod", excelDetail, index);
    },
  },
};
</script>

<style scoped lang="scss">
.salary-tabs {
  justify-content: center;
  display: flex;
  position: relative;

  .left-tab {
    margin-right: 80px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
  .right-tab {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }

  .tab-devider {
    position: absolute;
    width: 80px;
    height: 2px;
    background: #3378ff;
    border-radius: 2px;
    bottom: 0px;
    left: calc(50% - 158px);
    transform: translateX(-50%);
    transition: all 0.3s;

    &.is-right {
      width: 140px;
      left: calc(50% + 83px);
      transform: translateX(-50%);
    }
  }

  .tab-tip {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 17px;
  }
  .tab-tip-click {
    margin-bottom: 17px;
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #111111;
    line-height: 20px;
  }
}

.devider {
  height: 1px;
  background: #e8eaea;
  border: 1px #e8eaea;
}

/deep/ .salary-tab {
  .el-tabs__nav-scroll {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
}
/deep/.el-tabs__item {
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  color: #a2a2a7;
  line-height: 50px;
}

/deep/.is-active {
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #1f1f1f;
  line-height: 20px;
}
.content-upload {
  align-items: center;
  margin: 0 auto;
  width: 1240px;
  min-height: calc(100vh - 275px);
  padding-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(17, 31, 65, 0.1);
  border-radius: 0px 0px 8px 8px;
}
</style>

<style scoped lang="scss">
.content-upload {
  /deep/.common-down-qrcode {
    width: 340px;
    height: 140px;
    background: #ffffff;
    box-shadow: 0px 4px 14px 0px rgba(60, 90, 163, 0.1);
    border-radius: 3px;
    border: 1px solid #e1eaf7;
    box-sizing: border-box;
    margin: 10px auto 0;
    transform: translateX(22%);
    padding: 20px 0 20px 20px;

    & > img {
      width: 100px;
      height: 100px;
      margin-right: 15px;
    }

    & > div {
      width: calc(100% - 100px- 15px);
      display: flex;
      flex-direction: column;

      .codetip {
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }

      .downLoadbtn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 127px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #3d7fff;
        margin-top: auto;
        margin-bottom: -2px;
        font-size: 14px;
        font-weight: 400;
        color: #3d7fff;

        img {
          width: 20px;
          height: 20px;
          display: block;
        }
      }
    }
  }

  /deep/ .el-checkbox {
    // margin-right: 11px;
  }
}
</style>
