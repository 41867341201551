import FileSaver from "file-saver";

export default class fileSave {
  /**
     * 导出Excel文件
     * @param {*} res   文件流
     * @param {*} name  文件名
     * @param {*} type  文件类型
     * application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
     * application/vnd.ms-excel
     */
  static getExcel(res, name, type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    let blob = new Blob([res], { type });
    FileSaver.saveAs(blob, name);
  }
}

// const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
// const objectUrl = URL.createObjectURL(blob);
// const downloadEl = document.createElement("a");
// downloadEl.href = objectUrl;
// downloadEl.download = '工资条错误数据.xlsx';
// document.body.appendChild(downloadEl);
// downloadEl.click();
// document.body.removeChild(downloadEl);
// window.URL.revokeObjectURL(objectUrl);
