<template>
  <div>
    <el-dialog
      title="提示"
      custom-class="confirm-dialog"
      :visible="confirmVisible"
      @close="afterClose"
      width="40%"
    >
      <div slot>{{tips}}</div>
      
      <span slot="footer">
        <el-button @click="handler" size="medium">取 消</el-button>
        <el-button type="primary" @click="confirm" size="medium" class="confirm common-button">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "common-dialog",

  data() {
    return {};
  },

  props: {
    confirmVisible: {
      type: Boolean,
      default: false,
      require: true
    },
    tips: {
      type: String,
      default: "",
      require: true
    }
  },

  methods: {
    afterClose() {
      this.$emit("update:confirmVisible", false);
      this.$emit("close");
    },

    handler() {
      this.$emit("update:confirmVisible", false);
    },

    confirm() {
      this.$emit("update:confirmVisible", false);
      this.$emit("confirm");
    }
  }
};
</script>

<style scoped lang="scss">
/deep/ .confirm-dialog {
  width: 450px !important;
  height: 212px !important;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  .el-dialog__header {
    padding: 16px 16px 6px;
  }
  .el-dialog__footer {
    padding: 20px 30px 15px !important;
  }
  .el-button--default {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #3d7fff;

    font-size: 14px;
    font-weight: 400;
    color: #3d7fff;
  }
  .el-button--primary {
    background: #3d7fff;
    border-radius: 2px;
    border: 1px solid #3d7fff;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }
  .el-dialog__body {
    padding: 30px 30px 30px !important;
  }
  .input-with-select {
    width: 400px;
    margin-bottom: 20px;
  }
  .confirm {
    width: 80px;
    margin-left: 20px;
  }
}
</style>

