<template>
  <div>
    <el-dialog
      title="提示"
      custom-class="confirm-dialog"
      :visible="confirmVisible"
      @close="afterClose"
      width="796px"
      height="700px"
    >
      <div class="devider"></div>
      <div class="title" slot>{{ tips }}</div>
      <!-- <div v-if="settingInfo.shortMessage === 1" class="short-message-tip">
        注意：如员工已关注【我的工资】公众号，将以微信推送方式发送工资条，不再发送短信
      </div> -->
      <div class="dialog-body">
        <div class="wx-img" v-if="settingInfo.qyWxMessage === 1">
          <div class="img first">
            <img src="/qywx_qrcode.jpg" />
          </div>
          <div
            class="btn"
            @click="download('/qywx_qrcode_down.png', '企业微信.png')"
          >
            下载企业微信二维码
          </div>
        </div>

        <div
          :class="settingInfo.qyWxMessage === 1 ? '' : 'only-wx'"
          class="wx-img"
        >
          <div class="img">
            <img src="/qrcode.jpg" />
          </div>
          <div
            class="btn"
            @click="download('/wx_qrcode_down.png', '公众号二维码.png')"
          >
            下载公众号二维码
          </div>

          <div
            :style="{
              right: settingInfo.qyWxMessage === 1 ? '-266px' : '-294px',
            }"
            class="tip-message"
          >
            <img class="bubbles" src="../../assets/bubbles.png" />
            <div class="tip3">
              下载后将二维码群发给同事<br />让同事可以通过微信查看工资条
            </div>
          </div>
        </div>

        <div class="content">
          <div class="tip1">员工如何查看工资条？</div>
          <div class="tip2">
            微信：员工先关注微信公众号【我的工资】，发送工资条后，通过微信查看工资条;<br />
            邮件：员工登录邮箱接收工资条，查看工资条;<br />
            <!-- 短信：员工通过短信接收工资条，查看工资条;<br /> -->
            企业微信：员工先登录企业微信工作台【我的工资】，发送工资条后，通过企业微信查看工资条。
          </div>
        </div>
      </div>

      <div class="devider"></div>
      <span slot="footer">
        <el-button @click="handler" size="medium">取消</el-button>
        <el-button
          type="primary"
          @click="confirm"
          size="medium"
          class="confirm common-button"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "salary-send-dialog",

  data() {
    return {};
  },

  props: {
    confirmVisible: {
      type: Boolean,
      default: false,
      require: true,
    },
    tips: {
      type: String,
      default: "",
      require: true,
    },
    settingInfo: {
      type: Object,
      default() {
        return {};
      },
      require: true,
    },
  },
  mounted() {
    console.log("setting-info:", this.settingInfo);
  },
  methods: {
    afterClose() {
      this.$emit("update:confirmVisible", false);
      this.$emit("close");
    },

    handler() {
      this.$emit("update:confirmVisible", false);
    },

    confirm() {
      this.$emit("update:confirmVisible", false);
      this.$emit("confirm");
    },
  },
};
</script>

<style scoped lang="scss">
.short-message-tip {
  margin: 30px 30px -20px;
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.dialog-body {
  width: calc(100% - 60px);
  // height: 266px;
  height: auto;
  background: #f4f6ff;
  border-radius: 4px;
  margin: 30px auto 0;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  padding-top: 20px;

  .wx-img {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-top: 29px;

    &:first-child {
      margin: 0 40px 0 27px;
    }

    &.only-wx {
      margin: 0 29px 0 130px;
    }

    div.img {
      width: 134px;
      height: 134px;
      box-sizing: border-box;
      border: 1px solid #ccc;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }

      &.first {
        padding: 7px;
      }
    }

    div.btn {
      width: 134px;
      height: 34px;
      line-height: 34px;
      margin-top: 20px;
      text-align: center;
      background: #3d7fff;
      border-radius: 2px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
      margin-bottom: 30px;
    }
    div.tip-message {
      position: absolute;
      bottom: 12px;
      width: 241px;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;

      .bubbles {
        width: 100%;
        height: 100%;
      }

      .tip3 {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -100px;
        margin-top: -20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #6a6d82;
      }
    }
  }

  .left-container {
    padding-left: 34px;

    img {
      margin: 20px auto 0;
      display: block;
    }
  }
  .downLoadbtn {
    width: 120px;
    height: 34px;
    background: #455bf7;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 18px auto 0;
  }

  .content {
    margin-left: 30px;
    padding-right: 17px;
    background-color: transparent !important;
    .tip1 {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 10px;
    }
    .tip2 {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
  }
}
.title {
  margin-top: 22px;
  text-align: center;
  height: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 20px;
}
.devider {
  margin-top: 16px;
  height: 1px;
  background: #e8eaea;
  border: 1px #e8eaea;
}
/deep/ .confirm-dialog {
  // height: 490px !important;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  .el-dialog__header {
    padding: 16px 20px 6px;
  }
  .el-button--default {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #3d7fff;

    font-size: 14px;
    font-weight: 400;
    color: #3d7fff;
    width: 60px;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    padding: 0;
  }
  .el-button--primary {
    background: #3d7fff;
    border-radius: 2px;
    border: 1px solid #3d7fff;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    width: 60px;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    padding: 0;
  }
  .el-dialog__body {
    padding: 0px 0px 0px !important;
  }
  .input-with-select {
    width: 400px;
    margin-bottom: 20px;
  }

  .el-dialog__footer {
    padding: 20px 30px;
    height: 70px;
    background: white;
    border-radius: 6px;
  }
  .confirm {
    margin-left: 20px;
  }
}
</style>

