import axios from 'axios';
import axiosRetry from 'axios-retry';
import { environment } from '@/environment';
import { isCstGzt } from '../../../common/utils';

function getExecStrs (str) {
  var reg = /(&|\?)cstId=(\d+)&?/g
  var ret = reg.exec(str)
  
  return ret && ret[2]
}

// 获取cstId
let baseURL = environment.gzApi;
let cstId = sessionStorage.getItem('cstId')
if (cstId && isCstGzt()) {
  baseURL = environment.cstApi;
} else {
  //根据输入的URL判断是否来源于财税通
  // 如果url中包含“&cstId=”则视为财税通工资条
  let cstId = getExecStrs(window.location.href)
  cstId && sessionStorage.setItem('cstId', Number(cstId))
  if(cstId &&  isCstGzt()){
    baseURL = environment.cstApi
    sessionStorage.setItem('cstGzt','true')
  }
}

const gzApiInstance = axios.create({
  baseURL: baseURL,
  timeout: 30000
});


let pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let cancelToken = axios.CancelToken;
let removePending = config => {
  for (let p in pending) {
    if (pending[p].u === config.url + '&' + config.method + '&' + config.data) { //当当前请求在数组中存在时执行函数体
      pending[p].f('重复请求'); //执行取消操作
      pending.splice(p, 1); //把这条记录从数组中移除
    }
  }
}

axiosRetry(gzApiInstance, { retry: 3 });


gzApiInstance.interceptors.request
  .use(config => {
    config.withCredentials = true;

    return config;
  });

gzApiInstance.interceptors.request.use(config => {
  removePending(config);
  config.cancelToken = new cancelToken((c) => {
    pending.push({ u: config.url + '&' + config.method + '&' + config.data, f: c });
  });
  let cstId = sessionStorage.getItem('cstId');
  if(cstId  &&  isCstGzt()){
    let url = config.url;
    let index = url.indexOf('?')
    if(index===-1){
      config.url = `${url}?cstId=${cstId}`
    }else{
      config.url = `${url}&cstId=${cstId}`
    }
  }
  return config;
}, error => {
  return Promise.reject(error)
})

gzApiInstance.interceptors.response.use(res => {
  removePending(res.config);
  return res;
}, e => {
  // console.dir(e)
  if (e.response && e.response.status === 401 && location.host.indexOf('localhost') === -1) {
    location.href = environment.gwApi + "salary.aspx";
  }
  return Promise.reject(e);
});



export default gzApiInstance;
