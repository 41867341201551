import gzApiInstance from '@/axios/interceptor/gz';

//获取管理公司列表
function CompanyGet() {
  return gzApiInstance.get('/api/Company');
}
//获取公司详情
function CompanyDetailGet(companyId) {
  return gzApiInstance.get('/api/Company?companyId='+ companyId);
}
//保存公司信息
function CompanyPost(params) {
  return gzApiInstance.post('/api/Company', params);
}

//获取工资类型列表
function SalaryTypeGet(companyId) {
  return gzApiInstance.get(`/api/SalaryType?companyId=${companyId}`);
}
//新增/编辑类型
function SalaryTypePost(params) {
  return gzApiInstance.post(`/api/SalaryType`, params);
}
//删除类型
function SalaryTypeDELETE(params) {
  return gzApiInstance.delete(`/api/SalaryType`, {data: params});
}
//获取发送记录统计
function HomePageGet(companyId) {
  return gzApiInstance.get(`api/HomePage?companyId=${companyId}`);
}
//获取每月发送员工数
function HomePagePost(params) {
  return gzApiInstance.post(`api/HomePage`,params);
}
//导入excel表数据
function SalaryImport (params) {
  return gzApiInstance.post('api/SalaryImport',params);
}
//关联记账数据
function SalaryImporPut (params) {
  return gzApiInstance.put('api/SalaryImport',params);
}
//数据核验
function SalaryCheckPost (params) {
  return gzApiInstance.post('api/SalaryCheck',params);
}
//获取预览信息
function SalaryPreviewGet (companyId,salaryId,employeeSn,hide = false) {
  return gzApiInstance.get(`api/SalaryPreview?companyId=${companyId}&salaryId=${salaryId}&employeeSn=${employeeSn}&hide=${hide}`);
}
//修改某一项信息
function SalaryPreviewPost (params) {
  return gzApiInstance.post(`api/SalaryPreview`,params);
}
//获取工资条员工列表
function SalaryEmployeeGet (companyId,salaryId) {
  return gzApiInstance.get(`api/SalaryEmployee?companyId=${companyId}&salaryId=${salaryId}`);
}
//获取工资条条目列表
function SalaryItemGet (companyId,salaryId) {
  return gzApiInstance.get(`api/SalaryItem?companyId=${companyId}&salaryId=${salaryId}`);
}
//获取设置
function SalarySettingGet (companyId,salaryId) {
  return gzApiInstance.get(`api/SalarySetting?companyId=${companyId}&salaryId=${salaryId}`);
}
//保存设置
function SalarySettingPost (params) {
  return gzApiInstance.post(`api/SalarySetting`,params);
}
//撤回工资条
function RecallEmployeeSalaryMsgPost (params) {
  return gzApiInstance.post(`api/RecallEmployeeSalaryMsg`,params);
}
//获取发送记录列表
function SalaryMsgPost (params) {
  return gzApiInstance.post(`api/SalaryMsg`,params);
}
//获取员工工资条发送详情
function SalaryEmployeeMsgPost (params) {
  return gzApiInstance.post(`api/SalaryEmployeeMsg`,params);
}
function getSendType (salaryId) {
  return gzApiInstance.get(`api/SalaryMsg`,{
    params: {
      salaryId
    }
  });
}
//获取工资条发送结果
function SalaryEmployeeMsgGet (companyId,salaryId) {
  return gzApiInstance.get(`api/SalaryEmployeeMsg?companyId=${companyId}&salaryId=${salaryId}`);
}
//导出员工工资条发送详情
function SalaryEmployeeMsgPut (params) {
  return gzApiInstance.put(`api/SalaryEmployeeMsg`,params, {
    responseType: 'blob'
  });
}
//发送工资条
function Salarypost (params) {
  return gzApiInstance.post(`api/Salary`,params);
}
//从启信宝获取公司列表信息
function CompanyGetList (companyName) {
  return gzApiInstance.get(`api/Company?companyName=${encodeURIComponent(companyName)}`);
}
//删除工资条
function SalaryMsgDELETE(params){
  return gzApiInstance.delete(`/api/SalaryMsg`, {data: params});
}
function CompanyDELETE(companyId){
  return gzApiInstance.delete(`api/Company?companyId=${companyId}`);
}

function SalaryTemplateGet(){
  return gzApiInstance.get(`api/SalaryExport`, {
    responseType: 'blob'
  });
}

function SalaryTemplatePost(params){
  return gzApiInstance.post(`api/SalaryExport`, params, {
    responseType: 'blob',
  });
}

//获取用户该企业下最近发送工资的日期
function LatestSendTime(companyId){
  return gzApiInstance.put(`api/SalaryMsg?companyId=${companyId}`);
}

export default {
  CompanyGet,
  CompanyPost,
  CompanyDetailGet,
  SalaryTypeGet,
  SalaryTypePost,
  SalaryTypeDELETE,
  HomePageGet,
  HomePagePost,
  SalaryImport,
  SalaryImporPut,
  SalaryCheckPost,
  SalaryPreviewGet,
  SalaryPreviewPost,
  SalaryEmployeeGet,
  SalaryItemGet,
  SalarySettingGet,
  SalarySettingPost,
  getSendType,
  RecallEmployeeSalaryMsgPost,
  SalaryMsgPost,
  SalaryMsgDELETE,
  SalaryEmployeeMsgPost,
  SalaryEmployeeMsgPut,
  Salarypost,
  SalaryEmployeeMsgGet,
  CompanyGetList,
  CompanyDELETE,
  SalaryTemplateGet,
  SalaryTemplatePost,
  LatestSendTime,
}
