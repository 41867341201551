import jzApiInstance from '@/axios/interceptor/jz';
import cstJzInstance from '@/axios/interceptor/jz/cstJz';
import ycApiInstance from '../interceptor/jz/yecai';
import jzProApiInstance from '../interceptor/jz/pro';

// 获取账套列表
function GetAccountSetList() {
  let appId = sessionStorage.getItem('appTypeId');
  if(appId==300){
    //柠檬云财务软件免费版
    return jzApiInstance.get(`/Salary/Service/SalarySlip.ashx?cmd=GetAccountSetList`);
  }else if(appId==400){
    //柠檬云业财
    return ycApiInstance.get(`/Salary/Service/SalarySlip.ashx?cmd=GetAccountSetList`);
  }else if(appId==500){
    //柠檬云财务软件专业版
    return jzProApiInstance.get('/Salary/Service/SalarySlip.ashx?cmd=GetAccountSetList')
  }
}

//获取财税通的记账账套列表
function GetCstAccountSetList(){
  let id = Number(sessionStorage.getItem('cstId'))
  // return cstJzInstance.get(`MasterPages/Services/GetExternalAccountList.ashx?r=${new Date().getTime()}&externalId=${id}&userFlag=true`)
  return cstJzInstance.get(`Salary/Service/CstSalarySlip.ashx?cmd=GetAccountSetList&cstCompanyId=${id}`)
}

// 获取有工资的月份
function SalaryMonthList(as_id) {
  if(sessionStorage.getItem('cstGzt') === 'true'){
    return cstJzInstance.get('/Salary/Service/CstSalarySlip.ashx?cmd=GetYearMonth&asid='+as_id);
  }else{
    let appId = sessionStorage.getItem('appTypeId');
    if(appId==300){
      //柠檬云财务软件免费版
      return jzApiInstance.get('/Salary/Service/SalarySlip.ashx?cmd=GetYearMonth&asid='+as_id);
    }else if(appId==400){
      //柠檬云业财
      return ycApiInstance.get('/Salary/Service/SalarySlip.ashx?cmd=GetYearMonth&asid='+as_id);
    }else if(appId==500){
      //柠檬云财务软件专业版
      return jzProApiInstance.get('/Salary/Service/SalarySlip.ashx?cmd=GetYearMonth&asid='+as_id);
    }
  }
}
// 获取工资数据
function SalaryList(as_id, mid) {
  if(sessionStorage.getItem('cstGzt') === 'true'){
    let id = Number(sessionStorage.getItem('cstId'))
    return cstJzInstance.get('/Salary/Service/SalarySlip.ashx?cmd=GetSalaryList&asid='+ as_id + '&mid=' + mid + '&cstCompanyId=' + id);
  }else{
    let appId = sessionStorage.getItem('appTypeId');
    if(appId==300){
      return jzApiInstance.get('/Salary/Service/SalarySlip.ashx?cmd=GetSalaryList&asid='+ as_id + '&mid=' + mid);
    }else if(appId==400){
      //柠檬云业财
      return ycApiInstance.get('/Salary/Service/SalarySlip.ashx?cmd=GetSalaryList&asid='+ as_id + '&mid=' + mid);
    }else if(appId==500){
      return jzProApiInstance.get('/Salary/Service/SalarySlip.ashx?cmd=GetSalaryList&asid='+ as_id + '&mid=' + mid)
    }
  }
}

//获取弹窗广告
function getAdsInfo(){
  let param = {
    TypeId: 8010
  }
  return jzApiInstance.post('/Default/Services/GetPCAdvertisement.ashx', param, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'enctype': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  });
}

//点击广告
function clickAds(adId){
  let param = {
    TypeId: 8010,
    adId: adId
  }
  return jzApiInstance.post('/Default/Services/GetPCAdvertisement.ashx', param, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'enctype': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  })
}

//获取业财的asid
function YcAsid(jz_asid){
  return ycApiInstance.get(`/Settings/Services/GetAccountSetRelationScmAsId.ashx?asId=${jz_asid}`)
}


export default {
  GetAccountSetList,
  SalaryMonthList,
  SalaryList,
  GetCstAccountSetList,
  getAdsInfo,
  clickAds,
  YcAsid
}

