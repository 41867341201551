<template>
  <el-autocomplete
   :disabled="!canEditInfo" 
    @select="v => {$emit('select',lastResult,v);$emit('input',v)}"
    :maxlength="64"
    :value="value"
    @change="v => $emit('change',lastResult, v)"
    @input="v => $emit('input',v)"
    :placeholder="placeholder"
    :fetch-suggestions="querySearch"
    :trigger-on-focus="false"
  >
    <template slot-scope="{item}">{{item}}</template>
  </el-autocomplete>
</template>

<script>
export default {
  data() {
    return {
      lastQueryString: "",
      lastResult: [],
      resultList:[]
    };
  },
  props: {
    placeholder: String,
    value: String,
    module: Number,
    canEditInfo: Boolean
  },
  methods: {
    querySearch(queryString, cb) {
      var qs = queryString.trim();
      //   公司查询上次查询不到结果且本次包含上次，不再查询
      if (
        this.lastQueryString != "" &&
        this.lastResult.length <= 0 &&
        (qs.indexOf(this.lastQueryString) >= 0 && `${this.lastQueryString}`.length > 1)
      ) {
        cb([]);
        return;
      }
      console.log(this.lastQueryString, this.lastResult);
      //   查询字符串做trim，前后两次查询字符相同不做查询。
      if (qs == this.lastQueryString) {
        cb(this.resultList);
        return;
      }
      var self = this;
      self.lastQueryString = qs;
      self.$axios.gzApi.CompanyGetList(qs).then(
        res => {
          if (res.data) {
            self.lastResult = res.data.data || [];
            this.resultList = self.lastResult.map(lr => lr.companyName);
            cb(this.resultList);
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }
};
</script>