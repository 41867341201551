<template>
  <div width="150px" class="lemon-color">
    <div class="main-menu-container" style="z-index:99">
      <div class="main-menu" v-for="(mainMenu,idx) in menu" :key="idx" :class="{'hover': mainMenu.hover || mainMenu.active, 'active': mainMenu.active}">
        <div
          class="main-menu-icon"
          ref="menuIcon"
          @click="goto(mainMenu)"
          @mouseover="mainMenu.hover=true"
          @mouseout="mainMenu.hover=false"
        >
          <div class="menu-icon disable-select" v-if="mainMenu.hover || mainMenu.active">
            <img style="font-size:1.325rem" :src="mainMenu.icon1" />
          </div>
          <div class="menu-icon disable-select" v-else>
            <img style="font-size:1.325rem" :src="mainMenu.icon" />
          </div>
          <div class="disable-select menu-label">{{mainMenu.label}}</div>
        </div>
      </div>
    </div>
    <div v-if="store_menuActive" class="menu-model"></div>
  </div>
</template>

<script>
import mm from "@/scripts/mainMenu.js";
import { mapGetters } from 'vuex'

export default {
  name: "comm-menu",
  components: {},
  //   mixins: [systemArgs],
  computed: {
    ...mapGetters(['store_menuActive'])
  },
  data() {
    return {
      helpInfo: false,
      visible: false,
      permission: {},
      // menu: [],
      menu: mm,
      hoverMenuIndex: -1,
      managedByDz: 0
    };
  },
  props: {
    AppBody: HTMLElement
  },
  mounted() {},
  watch: {
    $route() {
      this.changeRoute();
    }
  },
  methods: {
    goto(mainMenu) {
      this.menu.filter(x => { x.active = false; })

      mainMenu.active = true;
      let url = mainMenu.path;
      this.$router.push(url);
    },
    changeRoute() {
      this.menu.filter(x => {
        x.active = false;
      }),
      this.menu.filter(x => {
        if (x.path == location.pathname) {
          x.active = true;
        }
      })
    }
  },
  created() {
    this.changeRoute()
  },
};
</script>

<style lang="scss" scoped>
.lemon-color {
  height: 100%;
}
.img-ning {
  width: 80px;
  height: 26px;
}
.menu-model {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 150px;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.001);
}
.disable-select {
  user-select: none;
}
.menu-label {
  text-align: center;
  float:left;
  font-weight: 400;
  color: #B5C1DF;
}

.menu-icon {
  margin: 0 14px 0 14px;
  overflow: hidden;
  line-height: 36px;
  text-align: center;
  font-size: 120%;
  float: left;

  img {
    width: 16px;
    height: 16px;
  }
}

.main-menu {
  cursor: pointer;
  font-size: 16px;
  color: #f8f8f8;
  font-weight: 400;
  cursor: default;
  position: relative;
  height: 50px;
  // margin-bottom: 10px;
  
  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    font-weight: 400;

    &:hover {
      text-decoration: none;
      color: #002140;
    }
  }

  .main-menu-icon {
    height: 100%;
    display: flex;
    align-items: center;
  }

  &.hover {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    background: rgb(61, 127, 255);

    .menu-label {
      color: #FFFFFF;
    }
    
    .menu-icon img {
      opacity: 1;
    }

    &.active {
      position: relative;
      
      &::before {
        position: absolute;
        content: '';
        width: 3px;
        height: 100%;
        background: linear-gradient(197deg, #5BB9FF 0%, #79C6FF 3%, #4F99F5 44%, #2747DC 100%, #2747DC 100%);
        box-shadow: 0px 0px 2px 0px rgba(34, 68, 134, 0.71);
        left: 0;
        top: 0;
      }
    }
  }
}

.sub-menu-container {
  position: absolute;
  line-height: 28px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(13, 13, 13, 1);
  left: 130px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  border-left-width: 0;
  z-index: 999999;
}

.sub-menu-item {
  display: flex;
}

.sub-menu-item .a {
  box-sizing: border-box;
  text-decoration: none;
  color: rgba(13, 13, 13, 1);
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  padding: 0 20px;
  display: block;
  width: 100%;
}

.sub-menu-item .a:active {
  background-color: #096dd9;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
}

.main-menu-container {
  background-color: #001529;
  width: 150px;
  top: 50px;
  left: 0px;
  position: fixed;
  transition: left 0.5s ease;
  bottom: 0;
}

.sub-menu-group:before {
  content: attr(data-title);
  color: rgba(0, 21, 41, 1);
  font-weight: 600;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 20px;
  display: block;
}

.sub-menu-group {
  padding: 8px 4px;
  text-align: left;
  float: left;
}

.sub-menu-group:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
