<template>
  <div class="company-content">
    <div class="company-info-item flex">
      <div class="label">发薪类型：</div>
      <div class="label-right">
        <el-select
            class="salaryType"
            popper-class="select-common-color"
            placeholder="薪资"
            v-model="typeName"
            :value="selectdefinedSalaryTypeIndex"
            @change="selectdefinedSalaryTypeIndexChange"
        >
          <el-option
              v-for="item in salaryTypedefinedList"
              :key="item.typeId"
              :label="item.typeName"
              :value="item.typeId"
          ></el-option>
        </el-select>
        <div class="label_set" @click="setSalaryType">设置</div>
      </div>
    </div>

    <salary-settype-dialog
        :confirm-visible.sync="salaryTypeconfirmVisible"
        @close="salaryTypeListget"
        :salaryTypeList="salaryTypedefinedList"
        :companyId="companyId"
    ></salary-settype-dialog>
    <div>
      <div class="company-info-item flex">
        <div class="label">发薪时间：</div>
        <div class="label-right">
          <div style="width: 340px;">
            <date-picker-salary
                :picker-val.sync="salaryYear"
                type="month"
                placeholder="选择年"></date-picker-salary>
          </div>
        </div>
      </div>

      <div class="company-info-item flex">
        <div class="label" style="margin-bottom: 3px"><span style="color:#FA6400 ;margin-right:4px;">*</span>发送方式：</div>
        <div class="label-right">
          <el-checkbox-group
              v-model="checkList"
              @change="handleCheckedChange"
          >
            <el-checkbox label="微信" @change="wexinChange"></el-checkbox>
            <el-checkbox label="邮件" @change="emailChange"></el-checkbox>
            <el-checkbox label="企业微信" @change="qyWeXinChange"></el-checkbox>
            <!-- <el-checkbox label="短信" @change="duanxinChange"></el-checkbox> -->
          </el-checkbox-group>
        </div>
      </div>

      <div class="common-down-qrcode flex" v-if="(qywxMessage && !isWX) || isWX">
        <img v-if="isWX" src="/qrcode.jpg"/>
        <img v-else src="/qywx_qrcode.jpg"/>

        <div>
          <div class="codetip" v-if="isWX">员工需要先关注“我的工资”<br>公众号，否则接收不到哦~</div>
          <div class="codetip" v-else>
            <div>请扫码使用“我的工资”</div>
            <div style="font-size: 12px; line-height: 17px; color: #666666;">企业员工需先登录企业微信，<br>才能接收到哦~</div>
          </div>
          <div class="downLoadbtn" @click="download(isWX ? '/wx_qrcode_down.png' : '/qywx_qrcode_down.png', isWX ? 'qrcode.png' : 'qywx_qrcode.png')">
            <img src="../../assets/salary-download-tip.png" style=""/>
            <div>下载二维码</div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center" style="margin-left: 150px; margin-top: 23px;">
      <div style="width: 340px"
        v-loading="loading"
        class="salary-button primary"
        :style="{'cursor': loading ? 'not-allowed' : 'pointer'}"
        element-loading-background="rgba(2255,2255,2255,0.6)"
        @click="isCanUpload && !loading && $refs.uploadInput.click()"
        :class="{'unwaite': !isCanUpload}">
        {{loading ? '工资表上传中' : '上传工资表'}}
        </div>
    </div>

    <input
      ref="uploadInput"
      style="display: none;"
      type="file"
      accept=".xls,.xlsx"
      id="importExcel"
      @change="upload"
      value="上传工资表"
    />
    <salary-upload-dialog
        :upload-visible.sync="uploadVisible"
        :sheets="sheets"
        :formData="formData"
        @confirm="confrimUpload"
    ></salary-upload-dialog>
    <div class="company-info-tip2">
      <div>
        <div class="info-tip1">
          <span style="color:#FA6400 ;margin-right:4px;">*</span>
          <div>请上传Excel格式的工资表（需包含姓名和手机号）</div>
        </div>
        <div class="info-tip1" style="margin-top:4px;">
          <div style="margin-left:10px;">您可以上传自定义模板；没有模板？</div>
          <span class="download" style="cursor: pointer;" @click="downLoadData">点击下载</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePickerSalary from '@/common/date-picker-salary';
import exportFile from "@/scripts/fileserve.js";

export default {
  name: "salary-selfdefined",
  components: {
    DatePickerSalary
  },
  inject: ["showTips2"],
  // inject: ["showTips1"],
  props: ["salaryTypeList"],
  data() {
    return {
      isCanUpload: false,
      checkList: [],
      salaryTypeconfirmVisible: false,
      uploadVisible: false,
      activeName: "first",
      companyId: 0,
      salaryYear: "",
      selectdefinedSalaryTypeIndex: 0,
      salaryTypeId: 0,
      monthList: [
        {
          monthId: "01",
          monthName: "1月"
        },
        {
          monthId: "02",
          monthName: "2月"
        },
        {
          monthId: "03",
          monthName: "3月"
        },
        {
          monthId: "04",
          monthName: "4月"
        },
        {
          monthId: "05",
          monthName: "5月"
        },
        {
          monthId: "06",
          monthName: "6月"
        },
        {
          monthId: "07",
          monthName: "7月"
        },
        {
          monthId: "08",
          monthName: "8月"
        },
        {
          monthId: "09",
          monthName: "9月"
        },
        {
          monthId: "10",
          monthName: "10月"
        },
        {
          monthId: "11",
          monthName: "11月"
        },
        {
          monthId: "12",
          monthName: "12月"
        }
      ],
      typeName: "",
      salaryTypedefinedList: [],
      excelDetail: {},
      sheets: [],
      sheetId: 0,
      formData: Object,
      isWX: false,
      wxMessage: 0,
      qywxMessage: 0,
      shortMessage: 0,
      emailMessage: 0,
      loading: false,
    };
  },
  watch: {
    salaryTypeList(salaryTypeList) {
      this.salaryTypedefinedList = [...salaryTypeList];
      if (this.salaryTypedefinedList.length) {
        this.typeName = this.salaryTypedefinedList[0].typeId;
        this.selectdefinedSalaryTypeIndex = this.salaryTypedefinedList[0].typeId;
      }
    }
  },
  created() {
    this.companyId = this.$store.getters.store_comanyId;
    this.salaryYear = new Date();
    let time = 30 * 24 * 60 * 60 * 1000;
    let lastDate = this.salaryYear.getTime() - time;
    this.salaryYear = lastDate;
  },
  mounted() {
    // console.log('页面加载完成')
  },
  methods: {
    downLoadData() {
      this.$axios.gzApi.SalaryTemplateGet().then(res => {
        if (res && res.data) {
          exportFile.getExcel(res.data, "工资模版.xlsx");
        }
      });
    },
    handleCheckedChange() {
      // console.log(this.checkList);
      if (this.checkList.length === 0) {
        this.isCanUpload = false;
      } else {
        this.isCanUpload = true;
      }
    },

    wexinChange(index) {
      // console.log(index);
      this.isWX = index;
      if (index) {
        this.wxMessage = 1;
      } else {
        this.wxMessage = 0;
      }
    },

    qyWeXinChange(index) {
      // console.log(index);
      if (index) {
        this.qywxMessage = 1;
      } else {
        this.qywxMessage = 0;
      }
    },
    duanxinChange(index) {
      // console.log(index);
      if (index) {
        this.shortMessage = 1;
      } else {
        this.shortMessage = 0;
      }
    },
    emailChange(index) {
      // console.log(index);
      if (index) {
        this.emailMessage = 1;
      } else {
        this.emailMessage = 0;
      }
    },
    //  弹窗
    setSalaryType() {
      this.salaryTypeconfirmVisible = true;
    },
    upload($event) {
      if (this.typeName === null || this.typeName === "") {
        this.$message({
          message: "请选择薪资类型",
          type: "warning",
          offset: 90
        });
        $event.target.value = null;
        return;
      }

      if ($event.target.files.length === 0) {
        return;
      }
      this.loading = true;
      const file = $event.target.files[0]
      if(file.size > 1024*1024*2){
        this.loading = false;
        $event.target.value = null;
        return this.$message.error('您上传的文件大小超过2M，请调整后重新上传');
      }
      this.formData = this.formDataReturn(file);
      this.confrimUpload(0, this.formDataReturn(file));
      $event.target.value = null;
    },

    formDataReturn(file) {
      const formDatas = new FormData();

      formDatas.append("file", file);
      formDatas.append("CompanyId", this.companyId);
      formDatas.append("CompanyName", this.$store.getters.store_companyName);
      formDatas.append("SalaryType", this.selectdefinedSalaryTypeIndex);
      formDatas.append("wxMessage", this.wxMessage);
      formDatas.append("shortMessage", this.shortMessage);
      formDatas.append("qyWxMessage", this.qywxMessage);
      formDatas.append("emailMessage", this.emailMessage);
      formDatas.append(
        "PayDate",
        new Date(this.salaryYear).getFullYear() +
          "-" +
          (new Date(this.salaryYear).getMonth() + 1)
      );

      return formDatas;
    },

    confrimUpload(sheetId, formData) {
      this.sheetId = sheetId;

      if (formData.delete) {
        formData.delete("SheetId");
      }

      formData.append("SheetId", this.sheetId);
      
      this.$axios.gzApi.SalaryImport(formData).then(res => {
        // console.log('www',res);
        if (res.data.code === 1000) {
          console.log(1111);
          if (res.data.data.isSuccess === false && res.data.data.sheets) {
            this.uploadVisible = true;
            this.sheets = res.data.data.sheets;
          } else {
            this.excelDetail = res.data.data;
            //上传成功
            // console.log("rr",this.checkList.length);
            this.$store.dispatch(
              "store_setsendTypeNumber",
              this.checkList.length
            );
            this.$emit("checkMethod", this.excelDetail, 1);
          }
        } else if (res.data.code == 9999) {
          this.$message({
            message: res.data.msg,
            type: "warning",
            offset: 90
          });
        }else if(res.data.code == 413){
          this.$message({
            message: res.data.msg,
            type: "warning",
            offset: 90
          });
        }
        this.loading = false;
      }).catch(error=> {
        console.log(error)
      })
    },
    /**
     * 获取工资类型列表
     */
    salaryTypeListget(companyId) {
      this.$emit("close", companyId);
    },
    selectdefinedSalaryTypeIndexChange(index) {
      this.selectdefinedSalaryTypeIndex = index;
    }
  }
};
</script>

<style scoped lang="scss">
.info-tip1 {
  color: #999999;
  display: flex;
}

/deep/ .el-checkbox__input {
  color: #333333 !important;
}

/deep/ .is-checked + {
  color: #333333 !important;
}

.downloadcode {
  display: flex;
  width: 340px;
  height: 140px;
  background: #ffffff;
  box-shadow: 0px 4px 14px 0px rgba(60, 90, 163, 0.1);
  border-radius: 3px;
  border: 1px solid #e1eaf7;

  .codetip {
    margin-top: 20px;
    width: 183px;
    height: 48px;
    font-size: 15px;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }

  .downLoadbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 127px;
    height: 30px;
    line-height: 22px;
    background: #ffffff;
    border: 1px solid #3d7fff;
    margin-top: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #3d7fff;
    cursor: pointer;
  }
}

/deep/ .el-checkbox__label {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.company-content {
  align-items: center;

  .company-info-item {
    margin-top: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .label {
      width: 41%;
      margin-right: 16px;
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }

    .label-right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 340px;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      color: #1f1f1f;
    }

    /deep/ .salaryType {
      .el-input {
        width: 340px;
        height: 36px !important;
        input {
          height: 36px !important;
        }
      }
    }

    .label_set {
      color: #3d7fff;
      font-size: 14px;
      margin-left: 8px;
      cursor: pointer;
      white-space: nowrap;
    }
  }

  .company-info-tip {
    margin-top: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #1f1f1f;
    text-align: center;
    height: 20px;
    color: #1f1f1f;
    line-height: 20px;
  }

  .company-info-tip2 {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 20px;
    margin-left: 13%;
  }

  .download {
    color: #3d7fff;
  }
  .salary-button{
    border: none;
  }
  .salary-button /deep/ .el-loading-mask .el-loading-spinner .circular{
    width: 32px;
    height: 32px;
    margin: 5px 150px 0 0;
  }
}
</style>
