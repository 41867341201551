import authApiInstance from '@/axios/interceptor/auth';

// 获取用户信息
function UserInfoGet() {
  return authApiInstance.get(`/v2/api/UserInfo`);
}

function VCodePost(params) {
  return authApiInstance.post(`/v2/api/Register`,params);
}
export default {
  UserInfoGet,
  VCodePost,

}

