<template>
  <div>
    <el-dialog
      title="提示"
      custom-class="confirm-dialog"
      :visible="confirmVisible"
      @close="afterClose"
      width="50%"
    >
      <div class="devider"></div>
      <div class="dialog-body">
        <el-input placeholder="请添加发薪类型" class="input-with-select" v-model="salaryType">
          <el-button slot="append" class="input-with-button" @click="addType">添加</el-button>
        </el-input>
        <el-table
          :data="salaryTypeLists"
          :border="true"
          style="width: 100%;margin-bottom:20px;"
          row-class-name="salary-type-item-table-row"
          height="250"
        >
          <el-table-column label="发薪类型">
            <template slot-scope="scope">
              <span v-if="!scope.row.isEdit">{{ scope.row.typeName }}</span>
              <el-input
                class="tip-input"
                align="right"
                v-if="scope.row.isEdit"
                v-model="scope.row.typeName"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{row, $index}">
              <div style="display: flex;">
                <div class="edit" v-if="!row.isEdit" @click="handleEdit($index, row)">编辑</div>
                <div class="edit" v-else @click="editData(row)">保存</div>
                <div class="edit" @click="handleDelete($index, row)">删除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "salary-settype-dialog",
  // inject: ["showTips2"],
  data() {
    return {
      // companyId: 0,
      salaryType: "",
      typeBean: {
        typeName: "",
        companyId: 0,
        typeId: 0
      },
      salaryTypeLists: []
    };
  },
  props: ["confirmVisible", "tips", "salaryTypeList", "companyId"],

  watch: {
    confirmVisible(confirmVisible) {
      // console.log(1, confirmVisible);
      if (confirmVisible) {
        // 弹窗显示了
      }
    },
    salaryTypeList(salaryTypeList) {
      this.salaryTypeLists = [...salaryTypeList].map(item => ({
        ...item,
        isEdit: false
      }));
      // console.log(this.salaryTypeLists);
    }
  },

  methods: {
    afterClose() {
      this.$emit("close", this.companyId);

      this.$emit("update:confirmVisible", false);
    },
    // 添加薪资类型
    addType() {
      if (this.salaryType === null || this.salaryType === "") {
        this.$message({
          message: "请填写发薪类型",
          type: "warning",
          offset: 90
        });
        return;
      } else {
        this.typeBean.companyId = this.companyId;
        this.typeBean.typeName = this.salaryType;
        this.typeBean.typeId = 0;
        this.$axios.gzApi.SalaryTypePost(this.typeBean).then(
          res => {
            if (res.data) {
              if (res.data.data) {
                this.salaryTypeListget(this.companyId, true);
                this.$message({
                  message: "添加成功！",
                  type: "success",
                  offset: 90
                });
              } else if (res.data.subCode === 1004 && res.data.code === 9999) {
                this.$message({
                  message: "发薪类型名称已被使用!",
                  type: "warning",
                  offset: 90
                });
              } else if (res.data.subCode === 1003 && res.data.code === 9999) {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                  offset: 90
                });
              }
            }
          },
          err => {
            console.log(" 添加薪资类型err===" + JSON.stringify(err));
          }
        );
      }
    },
    handleEdit(index, row) {
      row.isEdit = true;
    },
    editData(row) {
      if (row.typeName === null || row.typeName === "") {
        this.$message({
          message: "请填写发薪类型",
          type: "warning",
          offset: 90
        });
        return;
      } else {
        this.typeBean.companyId = this.companyId;
        this.typeBean.typeId = row.typeId;
        this.typeBean.typeName = row.typeName;
        this.$axios.gzApi.SalaryTypePost(this.typeBean).then(
          res => {
            // console.log(" 添加薪资类型===" + JSON.stringify(res));
            if (res.data) {
              if (res.data.data) {
                this.salaryTypeListget(this.companyId);
                this.$message({
                  message: "保存成功！",
                  type: "success",
                  offset: 90
                });
              } else if (res.data.subCode === 1004 && res.data.code === 9999) {
                this.$message({
                  message: "发薪类型名称已被使用!",
                  type: "warning",
                  offset: 90
                });
              } else if (res.data.subCode === 1003 && res.data.code === 9999) {
                this.$message({
                  message: res.data.msg,
                  type: "warning",
                  offset: 90
                });
              }
            }
          },
          err => {
            console.log("err===" + JSON.stringify(err));
          }
        );
      }
    },
    handleDelete(index, row) {
      if(this.salaryTypeLists.length<=1){
        this.$message.error('请至少保留一种类型');
        return;
      }
      console.log(index, row);
      this.typeBean.companyId = this.companyId;
      this.typeBean.typeId = row.typeId;
      this.$axios.gzApi.SalaryTypeDELETE(this.typeBean).then(res => {
        if (res.data.data) {
          this.salaryTypeListget(this.companyId);
          this.$message({
            message: "删除成功！",
            type: "success",
            offset: 90
          });
        }
      });
    },
    /**
     * 获取工资类型列表
     */
    salaryTypeListget(companyId, scrollToBottom = false) {
      // [...]
      this.$axios.gzApi.SalaryTypeGet(companyId).then(
        res => {
          // console.log("获取工资类型列表===" + JSON.stringify(res));
          if (res.data.data) {
            this.salaryTypeLists = res.data.data;
            this.salaryType = "";
            this.salaryTypeLists = this.salaryTypeLists.map(item => ({
              ...item,
              isEdit: false
            }));

            if (scrollToBottom) {
              this.$nextTick(() => {
                const domList = document.getElementsByClassName('salary-type-item-table-row');
                domList[domList.length - 1].scrollIntoView();
              })
            }
          }
        },
        err => {
          console.log("获取工资类型列表err===" + err);
        }
      );
    }
  }
};
</script>

<style scoped lang="scss">
/deep/.el-table__row:focus{
background: #f5f9ff;
}
/deep/.el-table__row:hover{
  background: #edf4ff;
}
/deep/.cell{
    padding: 0 20px !important;
    font-size: 14px;
    color: #333333;
  }

  /deep/.is-leaf {
  background: #f5f7fa;
  
}
/deep/.el-dialog__close,
.el-icon,
.el-icon-close {
  color: #666666;
}
.dialog-body {
  padding-left: 30px;
  padding-right: 30px;
}
.devider {
  // margin-top: 16px;
  height: 1px;
  background: #e8eaea;
  border: 1px #e8eaea;
}

/deep/ .confirm-dialog {
  background: #ffffff;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  width: 548px !important;
  height: 413px !important;
  .edit {
    height: 17px;
    font-size: 14px;
    font-weight: 400;
    color: #3d7fff;
    line-height: 17px;
    margin-right: 20px;

    cursor: pointer;
  }
  .el-dialog__header {
    padding: 16px 16px 10px;
  }
  .el-dialog__footer {
    padding: 10px 10px 15px !important;
  }

  .el-dialog__body {
    padding: 0px 0px 5px !important;
  }
  .input-with-select {
    width: 400px;
    margin-bottom: 20px;
    margin-top: 16px;
  }

  .input-with-button {
    width: 60px;
    display: block;
    font-size: 14px;
    background: #3d7fff !important;
    color: #ffffff;
    height: 40px;
    padding: 0;
    border-radius: 0px 4px 4px 0px;
  }
}
/deep/.el-input__inner {
  height: 40px;
}
</style>
