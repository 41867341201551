/* eslint-disable no-return-assign,one-var,no-unused-expressions,no-useless-escape,no-sequences,eqeqeq */
function add(arg1, arg2) {
  var r1, r2, m;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  return (arg1 * m + arg2 * m) / m;
}

function sub(arg1, arg2) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  //last modify by deeka
  //动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

function mul(arg1, arg2) {
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) { }
  try {
    m += s2.split(".")[1].length;
  } catch (e) { }
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
}

function div(arg1, arg2) {
  var t1 = 0,
    t2 = 0,
    r1,
    r2;
  try {
    t1 = arg1.toString().split(".")[1].length;
  } catch (e) { }
  try {
    t2 = arg2.toString().split(".")[1].length;
  } catch (e) { }
  r1 = Number(arg1.toString().replace(".", ""));
  r2 = Number(arg2.toString().replace(".", ""));
  return (r1 / r2) * Math.pow(10, t2 - t1);
}

function convertToDate(date) {
  if (!date) {
    return new Date();
  }
  if (typeof date == "object") {
    return date;
  } else {
    if (/\/Date\((\d{13})\)\//.test(date)) {
      return new Date(parseInt(RegExp.$1));
    } else if (
      /^(\d{4}([\/\-]\d{1,2}){2}([T\s]\d{2}(\:\d{2}){2})?)(\.\d{1,10})?(\+\d{2}\:\d{2})?$/.test(
        date
      )
    ) {
      let str = RegExp.$1.replace(/-/g, "/").replace("T", " ");
      return new Date(str);
    } else {
      return new Date();
    }
  }
}

// import chinaAreaCodeJson from "@/assets/chinaAreaCodeJson.json";

export default {
  install(Vue) {
    // 获取url参数
    Vue.prototype.$getUrlParams = function (name) {
      // name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]')
      // const regexS = '[\\?&]' + name + '=([^&#]*)'
      // var regex = new RegExp(regexS)
      // var results = regex.exec(window.location.search)
      // if (results === null) { return '' } else { return decodeURIComponent(results[1].replace(/\+/g, ' ')) }
      if (this.$route.params.hasOwnProperty(name)) {
        return this.$route.params[name];
      } else {
        return this.$route.query[name];
      }
    };
    // datepicker字符串转化为Date类型
    Vue.prototype.$str2Date = function (s) {
      if (!s) return new Date();
      var ss = s.split("-");
      var y = parseInt(ss[0], 10);
      var m = parseInt(ss[1], 10);
      var d = parseInt(ss[2], 10);
      if (!isNaN(y) && !isNaN(m) && !isNaN(d)) {
        return new Date(y, m - 1, d);
      } else {
        return new Date();
      }
    };
    // 加法运算
    Vue.prototype.$accAdd = function (a, b) {
      return add(a || 0, b || 0);
    };
    // 减法运算
    Vue.prototype.$accSub = function (a, b) {
      return sub(a || 0, b || 0);
    };
    // 乘法运算
    Vue.prototype.$accMul = function (a, b) {
      return mul(a || 0, b || 0);
    };
    // 除法运算
    Vue.prototype.$accDiv = function (a, b) {
      return div(a || 0, b || 1);
    };
    // 是否为空字符串
    Vue.prototype.$isEmpty = function (a) {
      if (a == undefined) return true;
      if (a == null) return true;
      if (typeof a === "string" && a.trim() == "") return true;
      return false;
    };
    Vue.prototype.$loadBaseData = function () {
      return window.AppVue && window.AppVue.loadBaseData();
    };
    Vue.prototype.$NumberFormat = function (number, decimals, showEmpty) {
      let n = number + "",
        d = isNaN(decimals) ? 0 : decimals * 1,
        result = 0,
        padZero = nn => {
          let r = "";
          for (let ii = 0; ii < nn; ii++) r = r + "0";
          return r;
        };
      //解决只有小数部分的数
      if (n.indexOf(".") == 0) {
        n = "0" + n;
      }
      //解决科学计数法
      var nes = n
        .toLowerCase()
        .split("e")
        .filter(_e => _e != "");
      if (nes.length > 1) {
        n = Number(n).toFixed(d);
      }
      n = n.replace(/[^0-9\.-]/g, "");
      n = n || "0";
      let ns = n.split(".");
      if (ns.length > 0) {
        ns[0] = ns[0].replace(/^0+/g, "") || "0";
      }
      if (ns.length > 1) {
        let xs = ns[1];
        if (xs.length < d) {
          result = ns[0] + "." + xs + padZero(d - xs.length);
        } else if (xs.length == d) {
          result = ns[0] + "." + xs;
        } else {
          let ls = xs[d];
          if (ls * 1 < 5) {
            result = ns[0] + "." + xs.substring(0, d);
          } else {
            let tmp = ns[0] + (d > 0 ? "." + xs.substring(0, d) : ""),
              li = tmp.length - 1,
              arr = [],
              hasAdd = false;
            for (; li >= 0; li--) {
              if (hasAdd) {
                arr[li] = tmp[li];
              } else {
                if (tmp[li] * 1 == 9) {
                  arr[li] = "0";
                } else {
                  if (isNaN(tmp[li])) {
                    arr[li] = tmp[li];
                    continue;
                  }
                  var __t = tmp[li] * 1;
                  arr[li] = __t + 1 + "";
                  hasAdd = true;
                }
              }
            }
            if (!hasAdd) arr.splice(0, 0, "1");
            result = arr.join("");
          }
        }
      } else {
        if (d > 0) {
          result = ns[0] + "." + padZero(d);
        } else {
          result = ns[0];
        }
      }

      if (/^[-0,\.]+?$/.test(result)) {
        if (showEmpty) {
          return "";
        } else {
          return result.replace(/-/g, "");
        }
      }
      if (/^0[0-9]*[1-9]+/g.test(result)) {
        result = result.replace(/^0[0-9]*([1-9]+)/g, "$1");
      }
      let num = result.replace(/\d{1,3}(?=(\d{3})+\.\d*?$)/g, "$&,");
      return num.replace(/\.$/g, "");
    };
    //一般数字格式化保留2位小数
    Vue.prototype.$AsNumber = function (number, showEmpty) {
      if (showEmpty == undefined || showEmpty == null) showEmpty = true;
      // if (!number) return "";
      return this.$NumberFormat((number + "").replace(/,/g, ""), 2, showEmpty);
    };
    Vue.prototype.$MessageWarning = function (msg) {
      if (msg && msg.length > 0) {
        this.$message.warning(msg);
        return;
      }
      this.$message.warning("页面异常，请稍后重试！");
    };
    //把日期字符串转换成格式yyyy-MM-dd
    Vue.prototype.$showDate = function (dateStr) {
      if (!dateStr) return "";
      if (dateStr == "0001-01-01T00:00:00") return "";
      let date = convertToDate(dateStr);
      if (date) {
        let m = date.getMonth() + 1;
        if (m < 10) {
          m = "0" + m;
        }
        let d = date.getDate();
        if (d < 10) {
          d = "0" + d;
        }
        return `${date.getFullYear()}-${m}-${d}`;
      }
      return "";
    };
    //获取日期字符串的时分秒
    Vue.prototype.$showTime = function (dateStr) {
      if (!dateStr) return "";
      if (dateStr == "0001-01-01T00:00:00") return "";
      let date = convertToDate(dateStr);
      if (date) {
        let h = date.getHours();
        if (h < 10) {
          h = "0" + h;
        }
        let m = date.getMinutes();
        if (m < 10) {
          m = "0" + m;
        }
        let s = date.getSeconds();
        if (s < 10) {
          s = "0" + s;
        }
        return h + ":" + m + ":" + s;
      }
      return "";
    };
    Vue.prototype.$inArray = function (el, arr) {
      var _tmp = arr || [];
      return _tmp.indexOf(el);
    };
    Vue.prototype.$copy = function (el) {
      return JSON.parse(JSON.stringify(el));
    };
    Vue.prototype.$download = function (res, fileName) {
      var reader = new FileReader();
      var self = this;
      reader.onload = function (event) {
        var content = reader.result;
        if (content.startsWith("{")) {
          let obj = JSON.parse(content);
          self.$message.warning(obj.Msg);
          return;
        }
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(res, fileName);
          return;
        }
        let obj = URL.createObjectURL(res);
        let a = document.createElement("a");
        a.innerHTML = fileName;
        a.download = fileName;
        a.href = obj;
        var evt = document.createEvent("MouseEvents");
        evt.initEvent("click", false, false);
        a.dispatchEvent(evt);
      };
      reader.readAsText(res);
    };
    Vue.prototype.$downloadWithPic = function (fileName, filePath) {
      let a = document.createElement("a");
      a.innerHTML = fileName;
      a.download = fileName;
      a.href = filePath;
      var evt = document.createEvent("MouseEvents");
      evt.initEvent("click", false, false);
      a.dispatchEvent(evt);
    };



    //使用关键字（keyword）在指定的列表中（list）检索指定的列（name）匹配后返回指定的字段（id）
    Vue.prototype.$getIds = function (list, keyword, name, id) {
      if (list && list.length && keyword) {
        return list
          .filter(item => {
            if (item.hasOwnProperty(name)) {
              return item[name] && item[name].indexOf(keyword) > -1;
            }
            return false;
          })
          .map(item => {
            if (item.hasOwnProperty(id)) {
              return item[id];
            }
            return 0;
          });
      }
      return [];
    };
    /************************************************************************
        添加账套操作日志
        type:操作类型{1010:创建账套,1020:复制账套,1030:恢复账套,1040:删除账套,
                     1050:修改名称,2010:增加权限,2020:删除权限,2030:移交账套}
        data:相关信息，结合type生成log描述
    ************************************************************************/
    window.doAddLog = true;
    Vue.prototype.$addAsLog = function (type, data, callback) {
      if (window.doAddLog) {
        let req = {
          Data: data,
          logType: type
        };
        this.$lmsvr.asLog
          .AddAsLog(req)
          .then(res => {
            callback && callback();
          })
          .catch(err => {
            callback && callback();
          });
      } else {
        callback && callback();
      }
    };
    //添加用户操作日志
    Vue.prototype.$addOpeLog = function (req) {
      if (window.doAddLog) {
        this.$lmsvr.OperationLog.AddOperationLog(req);
      }
    };
    //添加登录日志
    Vue.prototype.$addLoginLog = function (req) {
      if (window.doAddLog) {
        this.$lmsvr.LoginLog.AALoginLog(req);
      }
    };
    Vue.prototype.$getPermissionByText = function (pageText) {
      let perSet = this.store_PerSet;
      if (!perSet || !perSet.PermissionModuleSets) return {};
      var tempPer = null;
      perSet.PermissionModuleSets.forEach(mod => {
        mod.PageSets.forEach(per => {
          if (per.PageText == pageText) {
            if (perSet.IsSuperAdmin || perSet.IsAdmin || mod.CtrlAll) {
              for (let key of Object.keys(per.PermissionSet)) {
                per.PermissionSet[key] = true;
              }
            }
            tempPer = per.PermissionSet;
            tempPer.IsSuperAdmin = perSet.IsSuperAdmin;
            tempPer.IsAdmin = perSet.IsAdmin;
            tempPer.CtrlAll = mod.CtrlAll;
          }
        });
      });
      return tempPer;
    };
    //对所有用户开放的权限ID
    // Vue.prototype.$permissionToEveryone = [
    //   "units1010",
    //   "units1020",
    //   "categories1040",
    //   "assistingProperties"
    // ];
    Vue.prototype.$convertToDate = function (date) {
      return convertToDate(date);
    };
    //日期筛选框中的默认取值
    Vue.prototype.$defaultDateRange = function (startDate) {
      let date = new Date();
      let yyyy = date.getFullYear();
      let mm = date.getMonth() + 1;
      mm = mm > 9 ? mm : "0" + mm;
      let dd = date.getDate();
      dd = dd > 9 ? dd : "0" + dd;
      let start = `${yyyy}-${mm}-01`;
      if (this.$convertToDate(start) <= this.$convertToDate(startDate))
        start = startDate;
      return [start, `${yyyy}-${mm}-${dd}`];
      // return [`${yyyy}-${mm}-01`, `${yyyy}-${mm}-${dd}`];
    };
    //导出Excel
    Vue.prototype.$exportConfirm = function (conditions, exportAction) {
      this.$confirm("是否要导出单据明细?", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        distinguishCancelAndClose: true,
        closeOnClickModal: false,
        type: ""
      })
        .then(() => {
          conditions.ExportDetails = true;
          exportAction();
        })
        .catch(action => {
          if (action == "cancel") {
            conditions.ExportDetails = false;
            exportAction();
          }
        });
    };
    //获取纳税性质
    Vue.prototype.$getTaxTypeName = function (taxTypeId) {
      let taxType = this.store_UserTaxTypes || [];
      let item = taxType.find(t => t.Value == taxTypeId);
      if (item) {
        return item.Text;
      }
      return "";
    };
    //获取部门名称
    Vue.prototype.$getDepartmentName = function (departmentId) {
      if (departmentId == 0) {
        return "全公司";
      }
      let cust = this.store_Departments || [];
      let item = cust.find(c => c.DepartmentId == departmentId);
      if (item) {
        return item.DepartmentName;
      }
      return "";
    };
    //获取不包含已删除客户的客户缓存信息
    Vue.prototype.$getCustomerIsNotDelete = function () {
      let cust = this.store_CustSimple || [];
      let items = cust.filter(i => !i.IsDeleted);
      return items;
    };
    //获取不包含已删除客户的客户缓存信息
    Vue.prototype.$getCustomerIsNotDeleteOW = function () {
      let cust = this.store_CustSimpleOW || [];
      let items = cust.filter(i => !i.IsDeleted);
      return items;
    };
    //获取客户名称
    Vue.prototype.$getCustName = function (custId) {
      let cust = this.store_CustSimple || [];
      let item = cust.find(c => c.CustId == custId);
      if (item) {
        return item.CustName;
      }
      return "";
    };
    //获取客户名称
    Vue.prototype.$getCustNameOW = function (custId) {
      let cust = this.store_CustSimpleOW || [];
      let item = cust.find(c => c.CustId == custId);
      if (item) {
        return item.CustName;
      }
      return "";
    };
    //根据员工ID-获取员工名称
    Vue.prototype.$getEmployeeName = function (employeeId) {
      let cust = this.store_EmployeeSimple || [];
      let item = cust.find(c => c.EmployeeId == employeeId);
      if (item) {
        return item.EmployeeName;
      }
      return "";
    };
    //根据UserSn-获取员工名称
    Vue.prototype.$getEmployeeNameByUserSn = function (userSn) {
      let cust = this.store_EmployeeSimple || [];
      let item = cust.find(c => c.UserSn == userSn);
      if (item) {
        return item.EmployeeName;
      }
      return "";
    };
    //获取服务类型名称
    Vue.prototype.$getServiceTypeName = function (serviceTypeId) {
      let types = this.store_ServiceTypes || [];
      let item = types.find(c => c.ServiceTypeId == serviceTypeId);
      if (item) {
        return item.ServiceName;
      }
      return "";
    };
    //获取图片地址
    Vue.prototype.$getPicPath = function (path) {      
      if (path) {
        if(path.indexOf("http") == 0){
          return path;
        } else{
          return this.$lemonEnv.scm + path;
        }
      }
      return "";
    };
    Vue.prototype.$setRecentlyCustomer = function (custId) {
      if (!custId) return;
      let self = this;
      this.$lmsvr.Customer.UpdateCustAccessTime(custId).then(r => {
        if (r.Success) {
          self.$loadBaseData();
        } else {
          self.$MessageWarning(r.Message);
        }
      }).catch(err => {
        self.$MessageWarning();
      });
    };
    //获取某个页面的列设置信息
    Vue.prototype.$getColumnSets = function (pageId) {
      if (pageId) {
        let list = this.store_ColumnSets || [];
        if (list && list.length) {
          let item = list.find(t => t.pageId == pageId);
          if (item) {
            return item.colSets;
          }
        }
      }
      return [];
    };
  }
};
