<template>
  <div>
    <el-dialog
      title="上传工资表"
      custom-class="confirm-dialog"
      :visible="uploadVisible"
      @close="afterClose"
      @confirm="confrimUpload"
      width="40%"
    >
      <div class="devider"></div>
      <div class="dialog-body">
        <div class="company-info-tip">
          <img src="../../assets/salary-alert.png" style="width:15px;height:15px;margin-right:4px;" />
          提示：您上传的Excel表格包含多个工作表数据
        </div>
        <div class="company-info-item flex justify-start">
          <div class="label">请选择需要上传的Sheet：</div>
          <el-select
            placeholder
            popper-class="select-common-color"
            v-model="sheetName"
            :value="index"
            @change="indexChange"
            style="height:30px;"
          >
            <el-option
              v-for="item in sheets"
              :key="item.id"
              :label="item.sheetName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="devider" style="margin-top:33px;"></div>
      <div slot="footer">
        <el-button @click="afterClose" size="medium" class="cancle">取 消</el-button>
        <el-button type="primary" @click="confrimUpload" size="medium" class="confirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "salary-upload-dialog",
  data() {
    return {
      sheetName: "",
      index: 0
    };
  },
  props: ["uploadVisible", "formData", "sheets"],
  watch: {},

  methods: {
    afterClose() {
      this.$emit("update:uploadVisible", false);
      this.$emit("close", this.index, this.formData);
    },

    handler() {
      this.$emit("update:uploadVisible", false);
      this.$emit("close", this.index, this.formData);
    },

    confrimUpload() {
      this.$emit("update:uploadVisible", false);
      this.$emit("confirm", this.index, this.formData);
    },
    indexChange(index) {
      this.index = index;
      // console.log('this.index==' + this.index);
    }
  }
};
</script>

<style scoped lang="scss">
.devider {
  height: 1px;
  background: #e8eaea;
}
/deep/ .confirm-dialog {
  width: 500px !important;
  height: 250px !important;

  .el-dialog__footer {
    padding: 10px 20px 0px !important;
  }
  .el-dialog__header {
    padding: 16px 16px 6px;
  }

  .el-dialog__body {
    padding: 8px 0px 5px !important;
  }
  .input-with-select {
    width: 400px;
    margin-bottom: 20px;
  }
  .company-info-tip {
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 24px;
    text-align: left;
  }
  .company-info-item {
    margin-top: 16px;
    align-items: center;
    display: flex;
    justify-content: flex-start;

    .label {
      margin-right: 16px;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      color: #1f1f1f;
    }
  }
  .confirm {
    background: #3d7fff;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    border-radius: 2px;
    margin-left: 20px;
  }

  .cancle {
    // text-align: center;
    // height: 30px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #3d7fff;
    font-size: 14px;
    font-weight: 400;
    color: #3d7fff;
  }
}

.dialog-body {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 16px;
}

/deep/ .el-dialog__headerbtn ,
    .el-dialog__close{
color: black;
  }
</style>
