<template>
  <div class="container">
    <el-date-picker
        class="date-picker-salary"
        v-model="pickerValComputed"
        :type="type"
        :placeholder="placeholder"
        :clearable="clearable"
        :format="format"
        style="width: 100%"
    ></el-date-picker>
  </div>
</template>

<script>
export default {
  name: "date-picker-salary",
  props: {
    pickerVal: {
      required: true
    },
    type: {
      required: false
    },
    placeholder: {
      required: false,
      type: String
    },
    clearable: {
      required: false,
      default: false,
      type: Boolean
    },
    format: {
      required: false,
      type: String
    },
  },
  computed: {
    pickerValComputed: {
      get() {
        return this.pickerVal
      },
      set(v) {
        this.$emit('update:picker-val', v);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  .date-picker-salary {

    /deep/ input.el-input__inner {
      background: #ffffff;
      border: 1px solid #d6d6da;
      height: 36px;
      padding-left: 15px;
      cursor: default;

      &:hover {
        border: 1px solid#888888;
        background: #ffffff;
      }

      &:focus {
        background: #ffffff;
        border-radius: 2px;
        border: 1px solid #3d7fff;

        & ~ .el-input__prefix {
          .el-icon-date {
            &:before {
              background-image: url(../assets/canlende-click.png);
            }
          }
        }
      }
    }

    /deep/ .el-input__prefix {
      text-align: right;
      left: auto;
      right: 5px;

      .el-icon-date {
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
          content: '';
          width: 16px;
          height: 16px;
          display: block;
          background-image: url(../assets/canlende.png);
          background-color: transparent;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
