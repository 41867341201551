<template>
  <div class="content-send">
    <div class="content-previewsend">
      <div class="phone-preview">
        <div>
          <salary-preview
            :salaryItem="salaryItem"
            :previewData="previewData"
            :showNullColumn="setBean.showNullColumn"
            :isshowannouncement="setBean.showAnnouncement"
            :announcement="setBean.announcement"
          ></salary-preview>
          <div class="preview-tip">示例图</div>
        </div>
      </div>
      <div style="width:1px;height:460px;background:#E6E6E9;"></div>

      <div class="preview-set">
        <div class="preview-set-one">
          <div class="company-info-item2">
            <div class="tip"></div>
            <div class="tip_lable">显示设置</div>
          </div>
          <div class="company-info-item">
            <span class="tip_lable1">隐藏数据:</span>
            <el-select
              popper-class="select-common-color"
              class="no-height"
              multiple
              style="margin-left:10px;margin-right:16px;"
              v-model="salaryItem"
              filterable
              allow-create
              default-first-option
            >
              <el-option v-for="item in salaryData" :key="item" :label="item" :value="item"></el-option>
            </el-select>
            <img src="../../assets/salary-tip.png" />
            <span class="tip_lable2">被隐藏的项将不会显示在工资条中</span>
          </div>

          <div class="company-info-item">
            <!-- v-model="setBean.showNullColumn" -->
            <span class="tip_lable1">显示空值</span>
            <el-switch
              style="margin-left:14px;margin-right:16px;"
              active-color="#3D7FFF"
              v-model="setBean.showNullColumn"
            ></el-switch>
            <img src="../../assets/salary-tip.png" />
            <span class="tip_lable2">开启后工资条中将展示数值为空的项目</span>
          </div>

          <div class="company-info-item">
            <span class="tip_lable1">添加公告</span>
            <el-switch
              style="margin-left:14px;margin-right:16px;"
              v-model="setBean.showAnnouncement"
              active-color="#3D7FFF"
            ></el-switch>
            <img src="../../assets/salary-tip.png" />
            <div class="tip_lable2">手机查看时，公告将显示在工资条底部</div>
          </div>

          <el-input
            v-if="setBean.showAnnouncement"
            style="margin-top:20px;"
            class="textarea"
            type="textarea"
            placeholder="请输入内容"
            maxlength="70"
            v-model="setBean.announcement"
            show-word-limit
          ></el-input>
          <div class="company-info-item">
            <div class="tip"></div>
            <div class="tip_lable">功能设置</div>
          </div>
          <div class="company-info-item">
            <span class="tip_lable1">定时发送</span>
            <el-switch
              v-model="setBean.timerTask"
              active-color="#3D7FFF"
              @change="timeTaskChange"
              style="margin-left:14px;margin-right:16px;"
            ></el-switch>
          </div>
          <div class="company-info-item" v-if="setBean.timerTask">
            <span class="tip_lable1">发送时间</span>
            <!-- v-model="value1" -->
            <el-date-picker
              style="width:170px; margin-left:14px;margin-right:16px;"
              :editable="false"
              type="date"
              placeholder="选择日期"
              v-model="sendDate"
              :picker-options="pickerOptions"
            ></el-date-picker>
            <!-- v-model="value1" -->
            <el-time-picker
              :picker-options="{}"
              v-model="sendTime"
              format="HH:mm"
              popper-class="salary-time-picker"
            ></el-time-picker>
          </div>
        </div>

        <div class="footer-btn">
          <div style="width: 89px" class="salary-button default" @click="reUpload">上一步</div>

          <div style="width: 89px" class="salary-button primary" @click="setsend">发送</div>

          <!-- <div class="cancel" @click="reUpload">上一步</div> -->
          <!-- <div class="confirm" @click="setsend">发送</div> -->
        </div>

        <salary-send-dialog :setting-info="setData"
          :confirm-visible.sync="sendVisible"
          :tips="'您将发送' + payDate + '的工资条，是否确定继续?'"
          @confirm="sendSalary"
        ></salary-send-dialog>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "salary-previewsend",
  components: {},
  props: ["salaryId", "employeeSn"],
  data() {
    var todayDate = new Date(new Date().toLocaleDateString()).getTime();

    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < todayDate;
        }
      },
      value: "100",
      sendVisible: false,
      companyId: 0,
      salaryData: [],
      salaryItem: [],
      sendDate: "",
      sendTime: "",
      setData: {},
      previewData: [],
      checkList: [],
      setBean: {
        companyName: "",
        salaryId: 0,
        salaryType: 0,
        salaryDate: 0,
        accAsId: 0,
        sendTime: 0,
        createdBy: 0,
        createdDate: 0,
        modifiedBy: 0,
        modifiedDate: 0,
        companyId: 0,
        showNullColumn: 0,
        showAnnouncement: 0,
        timerTask: 0,
        sendTaskTime: 0,
        announcement: "",
        hideDataColumn: "",
        payDate: 0
      },
      payDate: ""
    };
  },
  created() {
    this.companyId = this.$store.getters.store_comanyId;
    this.sendDate = new Date();
    this.sendTime = new Date();
    let time = 5 * 60 * 1000;
    let sumtime = this.sendTime.getTime() + time;
    this.sendTime = sumtime;
    this.SalarySettingGet();
    this.SalaryItemGet();
    this.SalaryPriviewGet();
  },
  mounted() {},
  methods: {
    //  弹窗
    setsend() {
      this.prasepayDate(this.setBean.payDate);
      this.sendVisible = true;
    },
    sendSalary() {
      let param = {};
      for(let key in this.setBean){
        param[key] = this.setBean[key];
      }
      if (this.setBean.showNullColumn) {
        param['showNullColumn'] = 1;
      } else {
        param['showNullColumn'] = 0;
      }
      if (this.setBean.showAnnouncement) {
        param['showAnnouncement'] = 1;
        param['announcement'] = this.setBean.announcement;
      } else {
        param['showAnnouncement'] = 0;
        param['announcement'] = '';
      }
      if (this.setBean.timerTask) {
        param['timerTask'] = 1;
      } else {
        param['timerTask'] = 0;
      }
      if (this.sendDate) {
        let da =
          this.sendDate.getFullYear() +
          "-" +
          (this.sendDate.getMonth() + 1) +
          "-" +
          this.sendDate.getDate() +
          " " +
          (new Date(this.sendTime)).getHours() +
          ":" +
          (new Date(this.sendTime)).getMinutes() +
          ":" +
          (new Date(this.sendTime)).getSeconds();
        let date = new Date(da);

        this.setBean.sendTaskTime = date.getTime(); //时间戳
        param['sendTaskTime'] = this.setBean.sendTaskTime;
      }
      this.setBean.payDate;
      // 隐藏列(多个每项以[]包起来 英文逗号,分隔)
      this.setBean.hideDataColumn = "";
      param['hideDataColumn'] = "";
      if (this.salaryItem.length > 0) {
        console.log(this.salaryItem);
        for (let i = 0; i < this.salaryItem.length; i++) {
          if (i + 1 === this.salaryItem.length) {
            this.setBean.hideDataColumn += "[" + this.salaryItem[i] + "]";
            param['hideDataColumn'] += "[" + this.salaryItem[i] + "]";
          } else {
            this.setBean.hideDataColumn += "[" + this.salaryItem[i] + "]" + ",";
            param['hideDataColumn'] += "[" + this.salaryItem[i] + "]" + ",";
          }
        }
      }

      const loading = this.$loading({
        lock: true,
        text: '发送中',
        customClass: 'gz-salary-loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      });

      this.$axios.gzApi.SalarySettingPost(param).then(res => {
        loading.close();
        if (res.data.code == 1000 && res.data.data) {
          this.$store.dispatch("store_settimerTask", param['timerTask']);
          this.$store.dispatch(
            "store_setsendTaskTimer",
            this.setBean.sendTaskTime
          );
          this.$emit("checkResult", {index: 3, qyWxMessage: this.setData.qyWxMessage});
        } else {
          this.$message({
            message: res.data.msg || '未知错误',
            type: "error",
            offset: 90
          });
        }
      }).catch(e => {
        loading.close();
      });
    },

    reUpload() {
      this.$emit("reUpload", 2);
    },
    SalaryItemGet() {
      this.$axios.gzApi
        .SalaryItemGet(this.companyId, this.salaryId)
        .then(res => {
          // console.log(res);
          if (res.data) {
            this.salaryData = res.data.data || [];
          }
        });
    },

    SalarySettingGet() {
      this.$axios.gzApi
        .SalarySettingGet(this.companyId, this.salaryId)
        .then(res => {
          // console.log(res);
          if (res.data) {
            this.setData = res.data.data;
            this.setBean.companyId = this.setData.companyId;
            this.setBean.showNullColumn = this.setData.showNullColumn;
            this.setBean.showAnnouncement = this.setData.showAnnouncement;
            this.setBean.timerTask = this.setData.timerTask;
            // this.setBean.wxMessage = this.setData.wxMessage;
            // this.setBean.shortMessage = this.setData.shortMessage;
            // this.setBean.emailMessage = this.setData.emailMessage;
            this.setBean.sendTaskTime = this.setData.sendTaskTime;
            this.setBean.announcement = this.setData.announcement;
            this.setBean.payDate = this.setData.payDate;
            // console.log(1111, this.setData.payDate);
            this.setBean.companyName = this.setData.companyName;
            this.setBean.salaryId = this.setData.salaryId;
            this.setBean.salaryType = this.setData.salaryType;
            this.setBean.salaryDate = this.setData.salaryDate;
            this.setBean.accAsId = this.setData.accAsId;
            this.setBean.sendTime = this.setData.sendTime;
            this.setBean.createdBy = this.setData.createdBy;
            this.setBean.modifiedBy = this.setData.modifiedBy;
            this.setBean.modifiedDate = this.setData.modifiedDate;
            if (this.setData.hideDataColumn) {
              this.setBean.hideDataColumn = this.setData.hideDataColumn;
              this.salaryItem = eval(this.setBean.hideDataColumn);
            } else {
              this.setBean.hideDataColumn = "";
              this.salaryItem = [];
            }
            if (this.setBean.sendTaskTime) {
              this.praseDate(this.setBean.sendTaskTime);
            }
          }
        });
    },
    timeTaskChange() {
      console.log(1122, this.setBean.timerTask);
    },
    praseDate(time) {
      let tem = time;
      let date = new Date(tem);
      let year = date.getFullYear();
      let Month = date.getMonth() + 1;
      let day = date.getDate();
      this.sendDate = year + "-" + Month + "-" + day;
      this.sendTime =
        date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds();
    },
    prasepayDate(time) {
      let tem = time;
      let date = new Date(tem);
      let year = date.getFullYear();
      let Month = date.getMonth() + 1;
      this.payDate = year + "年" + Month + "月";
    },
    SalaryPriviewGet() {
      this.$axios.gzApi
        .SalaryPreviewGet(this.companyId, this.salaryId, this.employeeSn)
        .then(res => {
          if (res.data && res.data.code === 1000) {
            //跳到预览发送
            this.previewData = res.data.data;
          }
        });
    }
  }
};
</script>

<style scoped lang="scss">
.preview-tip {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.preview-set-one {
  min-height: 450px;
}
.content-send {
  padding: 10px 0;
  background: #ffffff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-left: 20px;
  align-items: center;
  margin: 0 auto;
  width: 1240px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(17, 31, 65, 0.1);
  border-radius: 0px 0px 6px 6px;
}
.phone-preview {
  width: 33%;
  margin-right: 50px;
  display: flex;
  justify-content: flex-end;
  // flex-direction: column;
}
.preview-set {
  width: 50%;
  margin-left: 40px;
  margin-top: -10px;
  height: 100%;
}
.content-previewsend {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  .company-info-item2 {
    align-items: center;
    display: flex;
    .tip {
      width: 4px;
      height: 15px;
      background: rgba(61, 127, 255, 1);
      border-radius: 2px;
    }
    .tip_lable {
      border-radius: 6px;
      margin-left: 6px;
    }
  }
  .company-info-item {
    align-items: center;
    display: flex;
    margin-top: 26px;
    /deep/ .el-select .el-tag__close.el-icon-close:hover {
      background-color: rgba(61, 127, 255, 1);
    }
    .tip {
      width: 4px;
      height: 15px;
      background: rgba(61, 127, 255, 1);
      border-radius: 2px;
    }
    .tip_lable {
      border-radius: 6px;
      margin-left: 6px;
    }
    .tip_lable1 {
      font-size: 14px;
      font-weight: 400;
      color: #1f1f1f;
    }
    .tip_lable2 {
      font-size: 14px;
      font-weight: 400;
      color: #1f1f1f;
      margin-left: 6px;
    }
  }
}
.footer-btn {
  // position: absolute;
  display: flex;
  margin-top: 20px;
  justify-content: flex-start;
  align-items: center;

  div {
    margin-left: 20px;

    &:first-child {
      margin-left: 0px;
    }
  }

  .confirm {
    width: 89px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3d7fff;
    border-radius: 2px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
  }

  .cancel {
    width: 89px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #3d7fff;
    margin-right: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #3d7fff;
  }
}

/deep/.textarea textarea {
  resize: none;
}

/deep/.preview-set-one .el-select .el-input__inner {
  min-height: 36px !important;
}
</style>
