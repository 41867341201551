import {
  Button,
  Select,
  Dialog,
  Table,
  TableColumn,
  Input,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Popover,
  Row,
  Col,
  Pagination,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Tabs,
  TabPane,
  Steps,
  Step,
  Option,
  OptionGroup,
  Switch,
  DatePicker,
  TimePicker,
  Checkbox,
  Tooltip,
  Loading,
  CheckboxButton,
  CheckboxGroup,
  ButtonGroup,
  Carousel,
  CarouselItem,
  Image
} from 'element-ui';
// Message,

import resetMessage from './resetMessage'

export function elementUiPlugin(Vue) {
  Vue.use(Button);
  Vue.use(Select);
  Vue.use(Dialog);
  Vue.use(Table);
  Vue.use(TableColumn);
  Vue.use(Input);
  Vue.use(Container);
  Vue.use(Header);
  Vue.use(Aside);
  Vue.use(Main);
  Vue.use(Footer);
  Vue.use(Row);
  Vue.use(Col);
  Vue.use(Popover);
  Vue.use(Pagination);
  Vue.use(Autocomplete);
  Vue.use(Dropdown);
  Vue.use(DropdownMenu);
  Vue.use(DropdownItem);
  Vue.use(Menu);
  Vue.use(Submenu);
  Vue.use(MenuItem);
  Vue.use(MenuItemGroup);
  Vue.use(Tabs);
  Vue.use(TabPane);
  Vue.use(Steps);
  Vue.use(Step);
  Vue.use(Option);
  Vue.use(OptionGroup);
  Vue.use(Switch);
  Vue.use(DatePicker);
  Vue.use(TimePicker);
  Vue.use(Checkbox);
  Vue.use(Tooltip);
  Vue.use(CheckboxButton);
  Vue.use(CheckboxGroup);
  Vue.use(ButtonGroup);
  Vue.use(Carousel);
  Vue.use(CarouselItem);

  // Vue.prototype.$message = Message;
  Vue.use(resetMessage);

  Vue.use(Loading.directive);

  Vue.prototype.$loading = Loading.service;
}

