<template>
  <div class="body-result">
    <div class="company-info-item">
      <template v-if="timerTask == 1">
        <span class="lable">预约发送：</span>
        <span class="lable1">{{total}}条</span>
        <span class="lable">发送时间：</span>
        <span class="lable1">{{time}}</span>
      </template>
      <template v-else>
        <template v-if="!resultList.sendingCount">
          <span class="lable">成功发送：</span>
          <span class="lable1">{{resultList.successCount}}条</span>
          <span v-if="resultList.failedCount != 0" class="lable">, 发送失败：</span>
          <span v-if="resultList.failedCount != 0" class="lable2">{{resultList.failedCount}}条</span>
        </template>
        <template v-else>
          <span class="lable">正在发送中：{{resultList.totalCount}}条</span>
          <img class="loading-icon" src="@/assets/loading.png" width="17" height="17" alt="">
        </template>
      </template>
    </div>
    <div class="footer-btn">
      <div style="width: 130px" class="salary-button primary" @click="checkRecord">查看发送记录</div>
    </div>

    <div class="content-item">
      <div class="content-tip">员工如何查看工资条？</div>

      <div class="content-text">
        <div>微信：员工先关注微信公众号【我的工资】，发送工资条后，通过微信查看工资条;</div>
        <div>邮件：员工登录邮箱接收工资条，查看工资条;</div>
        <!-- <div>短信：员工通过短信接收工资条，查看工资条;</div> -->
        <div>企业微信：员工先登录企业微信工作台【我的工资】，发送工资条后，通过企业微信查看工资条。</div>
      </div>

      <div class="flex img-container">
        <div class="wx-img" v-if="qyWxMessage === 1">
          <img src="/qywx_qrcode.jpg"/>
          <div class="salary-button primary" style="font-size: 15px" @click="download('/qywx_qrcode_down.png', '企业微信.png')">下载企业微信二维码</div>
        </div>

        <div class="wx-img">
          <img src="/qrcode.jpg"/>
          <div class="salary-button primary" style="font-size: 15px" @click="download('/wx_qrcode_down.png', '公众号二维码.png')">下载公众号二维码</div>
        </div>

        <div>
          <div class="tip3">下载后将二维码群发给同事<br>让同事可以通过微信查看工资条</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "salary-sendresult",
  components: {},
  data() {
    return {
      timerTask: false,
      sendTaskTime: 0,
      totalNum: 0,
      sendTypeNumber: 0,
      sendVisible: false,
      companyId: 0,
      salaryId: 0,
      resultList: {},
      total: 0,
      time: "",
      sendingTimer: 0
    };
  },
  props: {
    qyWxMessage: {
      require: true,
      default: 1,
      type: Number
    }
  },
  created() {
    this.companyId = this.$store.getters.store_comanyId;
    this.salaryId = localStorage.getItem("salaryId");
    this.sendTypeNumber = this.$store.getters.store_sendTypeNumber;
    this.totalNum = this.$store.getters.store_totalNumber;
    this.timerTask = this.$store.getters.store_timerTask;
    this.sendTaskTime = this.$store.getters.store_sendTaskTime;
    this.total = this.totalNum * this.sendTypeNumber;

    if (this.timerTask) {
      console.log(this.sendTaskTime)
      const date = new Date(this.sendTaskTime);
      const Y = date.getFullYear();
      const M = ('0' + (date.getMonth() + 1)).substr(-2);
      const D = ('0' + date.getDate()).substr(-2);
      const h = ('0' + date.getHours()).substr(-2);
      const m = ('0' + date.getMinutes()).substr(-2);

      this.time = `${Y}-${M}-${D} ${h}:${m}`;
    }

    this.SalaryEmployeeMsgGet();
  },
  destroyed() {
    clearTimeout(this.sendingTimer);
  },
  mounted() {},
  methods: {
    //  弹窗
    setsend() {
      this.sendVisible = true;
    },
    SalaryEmployeeMsgGet() {
      this.$axios.gzApi
        .SalaryEmployeeMsgGet(this.companyId, this.salaryId)
        .then(res => {
          if (res.data.data || res.data.code === 1000) {
            this.resultList = res.data.data;

            if (this.timerTask != 1 && this.resultList.sendingCount) {
              // 这是立即发送，正在发送中，轮询一下
              clearTimeout(this.sendingTimer);
              this.sendingTimer = setTimeout(() => {
                this.SalaryEmployeeMsgGet();
              }, 5000)
            }
          }
        });
    },
    checkRecord() {
      this.$router.push({
        path: "/salary/sendrecord"
      });
    },
  }
};
</script>

<style  scoped lang="scss">
.company-info-item {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 18px;

  .lable {
    margin-left: 6px;
    font-size: 16px;
    color: #666666;
  }
  .lable1 {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    margin-left: 4px;
    margin-right: 4px;
  }
  .lable2 {
    font-size: 14px;
    font-weight: 400;
    color: #f04124;
  }

}

.footer-btn {
  margin-top: 30px;
  align-items: center;
  display: flex;
  justify-content: center;

  .confirm1 {
    width: 120px;
    height: 34px;
    border-color: #3D7FFF !important;
    background: #3D7FFF !important;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 20px;
    position: absolute;
  }
  .confirm {
    height: 34px;
    border-color: #3D7FFF !important;
    background: #3D7FFF !important;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 11px !important;
    position: absolute;
  }
}
</style>

<style scoped lang="scss">
.bubbles {
  width: 189px;
  height: 48px;
  margin-left: 172px;
  position: absolute;
}

.body-result {
  align-items: center;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-top: 20px;
  align-items: center;
  margin: 0 auto;
  width: 1240px;
  height: calc(100vh - 20px);
  min-height: 558px;
  background: #ffffff;
  border-radius: 0px 0px 6px 6px;
}

.content-item {
  width: 1050px;
  background: #f4f6ff;
  margin: 60px auto 0;
  padding: 25px 0 32px calc(50% - 290px);
  box-sizing: border-box;

  .content-tip {
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 10px;
  }

  .content-text {
    margin-bottom: 30px;
   
    div {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }
  }

  .img-container {
    justify-content: flex-start;
    align-items: flex-end;

    .wx-img {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;


      &:first-child {
        margin: 0 50px 0 7px;
      }

      img {
        width: 134px;
        height: 133px;
        display: block;
        border-radius: 8px;
        border: 1px solid #BBBDC7;
        padding: 5px;
        box-sizing: border-box;
      }

      div {
        width: 140px;
        margin-top: 10px;
      }
    }

    .tip3 {
      font-size: 14px;
      font-weight: 400;
      color: #3E404C;
      line-height: 20px;
      padding: 11px 15px 11px 20px;
      background-color: #fff;
      border: 1px solid #DADBE4;
      position: relative;
      border-radius: 5px;
      margin-left: 15px;
      margin-bottom: -17px;

      &::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        left: -8px;
        background-color: #fff;
        border: 1px solid #DADBE4;
        top: 50%;
        transform: translateY(-50%) rotateZ(45deg);
      }

      &::after {
        content: '';
        position: absolute;
        width: 16px;
        height: 30px;
        background-color: #fff;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
      }

    }
  }
}
</style>
