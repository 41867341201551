import Vue from "vue";
import Vuex from "vuex";
import $axios from '../axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    comanyId: '',
    istest: false,
    companyName: '',
    sendTypeNumber: 0,
    totalNumber: 0,
    timerTask: false,
    sendTaskTime: 0,
    menuActive: false,
    userInfo: {},
    companyList: [],
    accountList: [],
    isCstGz: false,
    cstCompanyLimit: 0,
    cstCompanyNum: 0,
    adVisible: false,
    adInfo: {},
    buyErp: true,
    buyAcc: true, 
  },
  getters: {
    store_comanyId(state) {
      const comanyId = Number(sessionStorage.getItem("companyId"));
      if (!state.comanyId && comanyId) {
        return comanyId;
      }
      return Number(state.comanyId);
    },
    store_companyName(state) {
      const companyName = Number(sessionStorage.getItem("companyName"));

      if (!state.companyName && companyName) {
        return companyName;
      }
      return state.companyName;
    },
    store_istest(state) {
      return state.istest;
    },
    store_sendTypeNumber(state) {
      return state.sendTypeNumber;
    },
    store_totalNumber(state) {
      return state.totalNumber;
    },
    store_timerTask(state) {
      return state.timerTask;
    },
    store_sendTaskTime(state) {
      return state.sendTaskTime;
    },
    store_menuActive(state) {
      return state.menuActive
    },
    store_companyList(state) {
      // alert('修改公司列表：',JSON.stringify(state.companyList))
      let list = new Promise((resolve, reject) => {
        resolve(state.companyList)
      });

      if (!state.companyList.length) {
        list = $axios.gzApi.CompanyGet().then(({data: res}) => {
          return res.data || [];
        });
      }

      return list;
    },
    store_accountList(state) {
      let list = new Promise((resolve, reject) => {
        resolve(state.accountList)
      });

      if (!state.accountList.length) {
        if(state.isCstGz){
          list = $axios.cstGzApi.GetCstAccountSetList().then(({data: res}) => {
            return res.Data || [];
          });
        }else{
          if($axios.jzApi.GetAccountSetList()){
            list = $axios.jzApi.GetAccountSetList().then(({data: res}) => {
              return res.Data || [];
            });
          }
        }
      }
      return list;
    },
  },
  mutations: {
    $setCompanyId(state, values) {
      state.comanyId = values;
    },
    $setCompanyName(state, values) {
      state.companyName = values;
    },
    $setIsTest(state, values) {
      state.istest = values;
    },
    $setsendTypeNumber(state, values) {
      state.sendTypeNumber = values;
    },
    $settotalNumber(state, values) {
      state.totalNumber = values;
    },
    $settimerTask(state, values) {
      state.timerTask = values;
    },
    $setsendTaskTimer(state, values) {
      state.sendTaskTime = values;
    },
    $setMenuActive(state, values) {
      state.menuActive = values;
    },
    $setUserInfo(state, values) {
      state.userInfo = values;
    },
    $setCompanyList(state, values) {
      state.companyList = values;
    },
    $setAccountList(state, values) {
      state.accountList = values;
    },
    //是否财税通工资条
    $setCstGz(state, values){
      state.isCstGz = values;
    },
    //设置财税通工资条个数
    $setCompanyLimit(state, values){
      state.cstCompanyLimit = values;
    },
    //设置当前已经存在的公司
    $setCompanyNum(state, values){
      state.cstCompanyNum = values;
    },
    //修改广告的可见性
    $setAdVisible(state, values){
      state.adVisible = values;
    },
    //修改adInfo
    $setAdInfo(state, values){
      state.adInfo = values;
    },
    //是否购买ERP服务
    $setBuyErp(state, values){
      state.buyErp = values;
    },
    //是否购买财务账套
    $setBuyAcc(state, values){
      state.buyAcc = values;
    }
  },
  actions: {
    store_SetCompanyId(context, values) {
      sessionStorage.setItem("companyId", values);
      //用于再次登录时显示“上次使用的工资”（使用场景：
      // 1、财税通工作台点击软件应用中的工资条
      // 2、财税通创建公司
      // 3、财税通迁移公司
      // ）
      localStorage.setItem('companyId',values);
      context.commit('$setCompanyId', values);
    },
    store_SetCompanyName(context, values) {
      sessionStorage.setItem("companyName", values);
      //用于再次登录时显示“上次使用的工资”
      localStorage.setItem('companyName',values);
      context.commit('$setCompanyName', values);
    },
    store_setIsTest(context, values) {
      context.commit('$setIsTest', values);
    },
    store_setsendTypeNumber(context, values) {
      context.commit('$setsendTypeNumber', values);
    },
    store_settotalNumber(context, values) {
      context.commit('$settotalNumber', values);
    },
    store_settimerTask(context, values) {
      context.commit('$settimerTask', values);
    },
    store_setsendTaskTimer(context, values) {
      context.commit('$setsendTaskTimer', values);
    }
  }
})