<template>
  <div class="content-existcompany">
    <div class="company-info-item flex" v-if="!isCstGz">
      <div class="label">选择柠檬云软件:</div>
      <div class="label-right">
        <el-select
          class="salaryType"
          popper-class="select-common-color"
          v-model="selectedApp"
          filterable
          placeholder="请选择柠檬云软件"
          @change="handleAppChange"
        >
          <el-option
            v-for="(item, index) in appList"
            v-show="
              (item.app_id == 400 && buyErp) ||
              (item.app_id == 500 && buyAcc) ||
              item.app_id == 300
            "
            :key="'app' + index"
            :label="item.app_name"
            :value="item.app_id"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="company-info-item flex">
      <div class="label">{{ isCstGz ? "财务软件账套" : accountName }}:</div>
      <!-- <div class="label">财务软件账套:</div> -->
      <div class="label-right">
        <el-select
          class="salaryType"
          popper-class="select-common-color"
          v-model="salaryBean.asId"
          filterable
          :disabled="loadingFlag"
          placeholder="请选择对应软件账套"
          @change="selectAccountIndexChange"
        >
          <el-option
            v-for="item in accountList"
            :key="item.as_id"
            :label="item.as_name"
            :value="item.as_id"
          ></el-option>
        </el-select>
        <div
          v-if="
            !$store.state.isCstGz && (selectedApp == 300 || selectedApp == 500)
          "
          class="label_set"
          :class="dynamicDisplay"
          @click="gotoZhang"
        >
          进入账套
        </div>
        <div
          v-if="!$store.state.isCstGz && selectedApp == 400"
          class="label_set"
          :class="dynamicDisplay"
          @click="gotoZhang"
        >
          进入账套
        </div>
      </div>
    </div>

    <div class="company-info-item flex">
      <div class="label">工资月份:</div>
      <div class="label-right">
        <el-select
          popper-class="select-common-color"
          class="salaryType"
          v-model="salaryBean.salaryDate"
          placeholder="请选择对应工资表月份"
        >
          <el-option
            v-for="item in salaryMonthList"
            :key="item.itemId"
            :label="item.itemName"
            :value="item.itemId"
          ></el-option>
        </el-select>
      </div>
    </div>

    <div class="company-info-item flex">
      <div class="label">发薪类型:</div>
      <div class="label-right">
        <el-select
          class="salaryType"
          popper-class="select-common-color"
          v-model="salaryBean.salaryType"
          placeholder="薪资"
        >
          <el-option
            v-for="item in salaryTypeExisList"
            :key="item.typeId"
            :label="item.typeName"
            :value="item.typeId"
          ></el-option>
        </el-select>
        <div class="label_set" @click="setSalaryType">设置</div>
      </div>
    </div>

    <div class="company-info-item flex">
      <div class="label">发薪时间:</div>
      <div class="label-right">
        <div style="width: 340px">
          <date-picker-salary
            :picker-val.sync="salaryBean.payDate"
            type="month"
            placeholder="选择年"
          ></date-picker-salary>
        </div>
      </div>
    </div>

    <div class="company-info-item flex">
      <div class="label" style="margin-bottom: 3px">发送方式:</div>
      <div class="label-right">
        <el-checkbox-group
          style="margin-left: 14px; margin-right: 16px"
          v-model="checkList"
        >
          <el-checkbox
            label="微信"
            @change="checkBoxChange($event, 'wxMessage')"
          ></el-checkbox>
          <el-checkbox
            label="邮件"
            @change="checkBoxChange($event, 'emailMessage')"
          ></el-checkbox>
          <el-checkbox
            label="企业微信"
            @change="checkBoxChange($event, 'qywxMessage')"
          ></el-checkbox>
          <!-- <el-checkbox
            label="短信"
            @change="checkBoxChange($event, 'shortMessage')"
          ></el-checkbox> -->
        </el-checkbox-group>
      </div>
    </div>

    <div
      class="common-down-qrcode flex"
      v-if="
        (salaryBean.qywxMessage && !salaryBean.wxMessage) ||
        salaryBean.wxMessage
      "
    >
      <img v-if="salaryBean.wxMessage" src="/qrcode.jpg" />
      <img v-else src="/qywx_qrcode.jpg" />

      <div>
        <div class="codetip" v-if="salaryBean.wxMessage">
          员工需要先关注“我的工资”<br />公众号，否则接收不到哦~
        </div>
        <div class="codetip" v-else>
          <div style="font-size: 14px; line-height: 20px">
            请扫码使用“我的工资”
          </div>
          <div style="line-height: 16px; color: #666666">
            企业员工需先登录企业微信，<br />才能接收到哦~
          </div>
        </div>
        <div
          class="downLoadbtn"
          @click="
            download(
              salaryBean.wxMessage
                ? '/wx_qrcode_down.png'
                : '/qywx_qrcode_down.png',
              salaryBean.wxMessage ? 'qrcode.png' : 'qywx_qrcode.png'
            )
          "
        >
          <img src="../../assets/salary-download-tip.png" style="" />
          <div>下载二维码</div>
        </div>
      </div>
    </div>

    <div
      class="company-info-item flex justify-center align-center"
      style="margin-left: 150px; margin-top: 23px"
    >
      <div
        style="width: 340px"
        class="salary-button primary"
        @click="upload"
        :class="{ unwaite: !isCanClick }"
      >
        上传工资表
      </div>
    </div>

    <salary-settype-dialog
      :confirm-visible.sync="salaryTypeconfirmVisible"
      @close="salaryTypeListget"
      :salaryTypeList="salaryTypeExisList"
      :companyId="companyId"
    ></salary-settype-dialog>
  </div>
</template>

<script>
import DatePickerSalary from "@/common/date-picker-salary";
import { environment } from "@/environment";
import { mapState } from "vuex";

export default {
  name: "salary-existcompany",
  components: {
    DatePickerSalary,
  },
  props: ["salaryTypeList", "jzAsData", "source"],
  data() {
    return {
      checkList: [],
      isCnaClick: false,
      salaryTypeconfirmVisible: false,
      uploadVisible: false,
      activeName: "first",
      as_id: 0,
      app_as_id: 0,
      accountList: [],
      salaryMonthList: [],
      salaryBean: {
        salaryDate: null,
        asId: null,
        salaryType: null,
        payDate: "",
        wxMessage: 0,
        shortMessage: 0,
        emailMessage: 0,
        qywxMessage: 0,
      },
      selectedApp: 300,
      salaryTypeExisList: [],
      salary: "",
      monthList: [
        {
          monthId: "01",
          monthName: "1月",
        },
        {
          monthId: "02",
          monthName: "2月",
        },
        {
          monthId: "03",
          monthName: "3月",
        },
        {
          monthId: "04",
          monthName: "4月",
        },
        {
          monthId: "05",
          monthName: "5月",
        },
        {
          monthId: "06",
          monthName: "6月",
        },
        {
          monthId: "07",
          monthName: "7月",
        },
        {
          monthId: "08",
          monthName: "8月",
        },
        {
          monthId: "09",
          monthName: "9月",
        },
        {
          monthId: "10",
          monthName: "10月",
        },
        {
          monthId: "11",
          monthName: "11月",
        },
        {
          monthId: "12",
          monthName: "12月",
        },
      ],
      excelDetail: {},
      appList: [
        {
          app_name: "柠檬云财务免费版", // 柠檬云财务
          app_id: 300,
        },
        {
          app_name: "柠檬云财务专业版",
          app_id: 500,
        },
        {
          app_name: "柠檬云业财",
          app_id: 400,
        },
      ],
      accountName: "柠檬云财务免费版账套", //  柠檬云财务账套
      accountNameMap: {
        300: "柠檬云财务免费版账套", //  柠檬云财务账套
        400: "柠檬云业财账套",
        500: "柠檬云财务专业版账套",
      },
      loadingFlag: false,
      // dynamicDisplay: "",
    };
  },
  watch: {
    salaryTypeList(salaryTypeList) {
      this.salaryTypeExisList = [...salaryTypeList];
      if (this.salaryTypeExisList) {
        this.salaryBean.salaryType = this.salaryTypeExisList[0].typeId;
      }
    },
  },
  computed: {
    isCanClick() {
      if (
        this.checkList.length != 0 &&
        this.salaryBean.salaryDate &&
        this.salaryBean.salaryType &&
        this.salaryBean.asId &&
        this.salaryBean.payDate
      ) {
        return true;
      } else {
        return false;
      }
    },
    //进入账套动态class
    dynamicDisplay() {
      return (this.selectedApp == 400 || this.selectedApp == 500) &&
        !this.salaryBean.asId
        ? "disabled-btn"
        : "";
    },
    ...mapState(["isCstGz", "buyErp", "buyAcc"]),
  },
  created() {
    //根据source初始化下拉框的值
    switch (this.source) {
    case "jz":
      this.selectedApp = 300;
      break;
    case "erp":
      this.selectedApp = 400;
      break;
    case "pro":
      this.selectedApp = 500;
      break;
    }
    if (!this.isCstGz) {
      sessionStorage.setItem("appTypeId", this.selectedApp);
    }
    this.companyId = this.$store.getters.store_comanyId;
    this.salaryBean.payDate = Date.now() - 30 * 24 * 60 * 60 * 1000;

    this.$nextTick(() => {
      this.GetAccountSetList(this.selectedApp);
    });
  },
  mounted() {},
  methods: {
    checkBoxChange(index, type = "qywxMessage") {
      this.salaryBean[type] = index ? 1 : 0;
    },
    gotoZhang() {
      if (this.selectedApp !== 300 && !this.salaryBean.asId) {
        this.$message.error("请先选择要进入的账套！");
        return;
      }
      //云业财地址：https://erptest.ningmengyun.com/#/from_gzt?dashboard&asid=200000846&readyUrl=Salary/SalaryManage
      const item = this.accountList.find(
        (x) => x.as_id == this.salaryBean.asId
      );
      let href = "";
      let baseUrl = environment.jzApi;
      switch (this.selectedApp) {
      case 300: {
        baseUrl = environment.jzApi;
        //记账版
        href = baseUrl + "/App/Default.aspx?appasid=" + this.app_as_id;
        //代账版
        if (item && item.permission == 10010) {
          href =
            environment.dzApi +
            "/App/Default.aspx?appasid=" +
            this.app_as_id +
            "&AACompanyId=" +
            item.aa_company_id;
        }
        window.open(href);
        break;
      }
      case 400: {
        baseUrl = environment.ycFront;
        this.getYcAsid(item.as_id, baseUrl);
        break;
      }
      case 500: {
        baseUrl = environment.erp + "wb/redirect/setService";
        //记账版
        href = baseUrl + "?productType=acc&asid=" + item.as_id;
        //代账版
        if (item && item.permission == 10010) {
          href =
            baseUrl +
            "?productType=aa&asid=" +
            item.as_id +
            "&AACompanyId=" +
            item.aa_company_id;
        }
        window.open(href);
        break;
      }
      }
    },
    //获取业财的id
    getYcAsid(as_id, baseUrl) {
      this.$axios.jzApi
        .YcAsid(as_id)
        .then((response) => {
          let { data } = response;
          if (data.state === 1000) {
            const href = baseUrl + `/#/from_acc?dashboard&asid=${data.data}`;
            window.open(href);
          } else {
            this.$message.error(data.msg ? data.msg : "业财编号查询失败");
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error("业财编号查询失败");
        });
    },
    //  弹窗
    setSalaryType() {
      this.salaryTypeconfirmVisible = true;
    },
    upload() {
      if (!this.isCanClick) {
        return;
      }
      this.$axios.jzApi
        .SalaryList(this.salaryBean.asId, this.salaryBean.salaryDate)
        .then((res) => {
          if (res.data) {
            // put请求
            const Params = {
              ...this.salaryBean,
              companyId: this.companyId,
              companyName: this.$store.getters.store_companyName,
              jzDataDetail: res.data.Data,
              payDate: new Date(this.salaryBean.payDate).getTime(),
            };
            this.SalaryImporPut(Params);
          }
        });
    },
    // 获取账套列表
    GetAccountSetList(app) {
      this.loadingFlag = true;
      if (this.isCstGz) {
        this.$axios.jzApi.GetCstAccountSetList().then((res) => {
          this.handleCstAcctList(res);
        });
      } else {
        this.$axios.jzApi.GetAccountSetList().then((res) => {
          //防止响应太慢时，选择的软件已切换，导致数据对应不上
          app == this.selectedApp && this.handleAccountSetList(res);
        });
      }
    },
    // //处理财税通记账的账套列表
    handleCstAcctList(ret) {
      if (ret) {
        this.accountList = ret.AccountList || [];
        this.$store.commit("$setAccountList", this.accountList);
        if (this.accountList.length == 0) {
          this.loadingFlag = false;
          return;
        }

        let item = this.accountList[0];
        if (this.jzAsData && this.jzAsData.asid) {
          // jz 进入
          const itemTemp = this.accountList.find(
            (x) => x.ASID == this.jzAsData.asid
          );

          item = itemTemp || item;

          this.setCompanyByAsid(this.jzAsData.asid).then(() => {
            this.companyId = this.$store.getters.store_comanyId;
            this.salaryTypeListget();
          });
        }

        this.salaryBean.asId = item.as_id;
        this.app_as_id = item.app_as_id;

        this.SalaryMonthList(this.salaryBean.asId, true);
      }
      this.loadingFlag = false;
    },
    //处理原版记账的账套列表
    handleAccountSetList(res) {
      if (res.data) {
        let tempList = [];
        if (this.selectedApp == 400 || this.selectedApp == 500) {
          tempList = res.data.Data;
        } else {
          tempList = Array.from(res.data.Data || []).filter(
            (item) => item.permission > 0
          );
        }
        this.accountList = tempList || [];
        this.$store.commit("$setAccountList", this.accountList);
        if (this.accountList.length == 0) {
          // this.selectedApp === 400 && (this.dynamicDisplay = 'disabled-btn');
          this.selectedApp === 400;
          this.loadingFlag = false;
          return;
        }
        let item = tempList[0];
        if (this.jzAsData && this.jzAsData.asid) {
          // jz 进入
          const itemTemp = this.accountList.find(
            (x) => x.as_id == this.jzAsData.asid
          );

          item = itemTemp || item;

          this.setCompanyByAsid(this.jzAsData.asid).then(() => {
            this.companyId = this.$store.getters.store_comanyId;
            this.salaryTypeListget();
          });
        }

        this.salaryBean.asId = item.as_id;
        this.app_as_id = item.app_as_id;

        this.SalaryMonthList(this.salaryBean.asId, true);
      }
      this.loadingFlag = false;
    },
    //柠檬云软件变更监听
    handleAppChange(index) {
      this.accountName = this.accountNameMap[index];
      sessionStorage.setItem("appTypeId", index);
      //重新调用API
      //获取账套列表
      this.salaryBean.asId = null;
      this.accountList = [];
      this.GetAccountSetList(this.selectedApp);
    },
    //账套变更监听
    selectAccountIndexChange(index) {
      this.salaryBean.asId = index;
      for (let i = 0; i < this.accountList.length; i++) {
        if (this.salaryBean.asId === this.accountList[i].as_id) {
          this.app_as_id = this.accountList[i].app_as_id;
        }
      }
      this.SalaryMonthList(this.salaryBean.asId);
    },
    SalaryMonthList(as_id, firstIn = false) {
      this.$axios.jzApi.SalaryMonthList(as_id).then((res) => {
        this.salaryMonthList = [];
        if (res.data && (res.data.Data || []).length) {
          this.salaryMonthList = (res.data.Data || []).map((item) => ({
            itemId: item,
            itemName: `${`${item}`.slice(0, 4)}年${Number(
              `${item}`.slice(4)
            )}月`,
          }));

          if (this.jzAsData && this.jzAsData.period && firstIn) {
            this.salaryBean.salaryDate = this.jzAsData.period;
          } else {
            this.salaryBean.salaryDate = this.salaryMonthList[0].itemId;
          }
        } else {
          this.salaryBean.salaryDate = null;
        }
      });
    },
    /**
     * 获取工资类型列表
     */
    salaryTypeListget(companyId) {
      this.$emit("close");
    },
    // 关联记账数据
    SalaryImporPut(Params) {
      if (!this.isCstGz) {
        let typeId = sessionStorage.getItem("appTypeId");
        if (!typeId) return this.$message.error("数据异常");
        Params["accDataFrom"] = typeId == 300 ? 1010 : 1020;
      }
      this.$axios.gzApi.SalaryImporPut(Params).then((res) => {
        if (res.data) {
          if (res.data.code === 1000) {
            //上传成功
            //要保存res.data.data
            this.excelDetail = res.data.data;
            this.$store.dispatch(
              "store_setsendTypeNumber",
              this.checkList.length
            );
            this.$emit("checkMethod", this.excelDetail, 1);
          } else {
            this.$message({
              message: "当前账套的工资月份不存在工资数据，请重新选择！",
              type: "warning",
              offset: 90,
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-checkbox__input {
  color: #333333 !important;
}
/deep/.is-checked + {
  color: #333333 !important;
}

.content-existcompany {
  align-items: center;

  .company-info-item {
    margin-top: 19px;
    justify-content: center;
    align-items: center;
    .label {
      width: 41%;
      margin-right: 16px;
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      color: #1f1f1f;
      line-height: 20px;
      height: 20px;
    }
    .label-right {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 57%;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      color: #1f1f1f;
      line-height: 20px;
    }

    /deep/ .salaryType {
      .el-input {
        width: 340px !important;
        height: 36px !important;

        input {
          height: 36px !important;
        }
      }
    }
    .label_set {
      color: #3d7fff;
      font-size: 14px;
      margin-left: 8px;
      cursor: pointer;
    }
    .disabled-btn {
      color: #aaa;
      cursor: not-allowed;
    }
  }

  .company-info-tip {
    margin-top: 40px;
    height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 22px;
    text-align: center;
  }
}
</style>
