<template>
  <div class="content-sendrecord-item">
    <div class="content-item" v-for="(item, i) in salaryLists" :key="i">
      <div style="display: none" class="cancel" @click="deleteclick(i)" v-if="item.showDelete">
        <img src="../../assets/salary-remove.png" style="padding-top:14px;padding-right:14px;" />
      </div>
      <div class="delete-place" v-if="item.showDelete" style="height: 26px"></div>
      <div class="cancel" v-if="!item.showDelete"></div>
      <div class="preview_title" @click="itemclick(item)">
        <div class="tip1">
          <div class="timetip" v-if="item.timerTask === 1"></div>
          {{formateDate(item.payDate)}}{{item.salaryType}}
        </div>
        <div class="tip">
          <div class="tip2">{{moneyParse(item.employeeCount)}}</div>
          <div class="tip3">人</div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="send-status" v-if="item.istime ==false">
        <img
          src="../../assets/salary-havesend.png"
          style="margin-right:6px;width:16px;height:16px;"
        />
        <div class="status" style="color: #666666;">已发送</div>
        <div class="status">发送时间:</div>
        <div class="status">{{ item.sendTime | date('yyyy-MM-dd HH:mm')}}</div>
      </div>
      <div class="send-status" v-if="item.istime == true">
        <div class="status-time">
          <img src="../../assets/salary-timetask.png" style="margin-left:4px;margin-right:3px;" />
          <div class="status1">定时发送</div>
        </div>

        <div class="status2">将于</div>
        <div class="status2">{{ item.sendTaskTime | date('yyyy-MM-dd HH:mm')}}</div>
        <div class="status2">发送</div>
      </div>
    </div>
    <common-dialog
      :confirm-visible.sync="confirmVisible"
      :tips="'您将删除'+formateDate(deletItem.payDate)+'的定时发送，是否确定继续？'"
      @confirm="deleteRecord"
    ></common-dialog>
  </div>
</template>

<script>
export default {
  name: "salary-sendrecord-item",
  props: ["salaryList"],
  components: {},
  data() {
    return {
      confirmVisible: false,
      deleteBean: {
        companyId: 0,
        salaryId: 0
      },
      deletItem: {},
      salaryLists: []
    };
  },
  watch: {
    // salaryList(salaryList) {
    //   console.log(222, salaryList)
    // }
  },
  created() {
    this.deleteBean.companyId = this.$store.getters.store_comanyId;
    this.salaryLists = [...this.salaryList].map(item => ({
      ...item,
      istime: false
    }));
    for (let i = 0; i < this.salaryLists.length; i++) {
      if (this.salaryLists[i].timerTask == 1) {
        if (this.salaryLists[i].sendTime) {
          //不为null,证明已发送
          this.salaryLists[i].istime = false;
        } else {
          this.salaryLists[i].istime = true;
        }
      } else {
        this.salaryLists[i].istime = false;
      }
    }
  },
  mounted() {},
  methods: {
    moneyParse(value) {
      // 金额 格式化
      if (!value && value !== 0) return "-";
      var intPart = Number(value) | 0; //获取整数部分
      var intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断 8,695.00

      return intPartFormat;
    },
    formateDate(date) {
      let formateDate = new Date(date);
      return (
        formateDate.getFullYear() + "年" + (formateDate.getMonth() + 1) + "月"
      );
    },
    deleteclick(index) {
      this.confirmVisible = true;
      this.deleteBean.salaryId = this.salaryList[index].salaryId;
      this.deletItem = this.salaryList[index];
    },
    deleteRecord() {
      this.$axios.gzApi.SalaryMsgDELETE(this.deleteBean).then(res => {
        // console.log(res);
        if (res.data) {
          // this.$message("删除成功！");
          this.$message({
            message: "删除成功！",
            type: "success",
            offset: 90
          });
          this.$emit("getSalaryList");
        }
      });
    },
    itemclick(item) {
      this.$router.push({
        path: "/salary/sendrecorddetail",
        query: { salaryId: item.salaryId, sendType: 1020, salaryType: item.salaryType, isFromList: 1 }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.timetip {
  width: 8px;
  height: 8px;
  background: #3d7fff;
  border-radius: 4px;
  margin-right: 6px;
}
.content-sendrecord-item {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 16px;
}
.content-item {
  width: 280px;
  height: 180px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #d6d6da;
  width: calc(25% - 30px) !important;
  margin-left: 20px;
  margin-top: 20px;
  &:hover {
    .cancel {
      display: flex !important;
    }
    .delete-place {
      display: none !important;
    }
  }
  .cancel {
    width: 40px;
    height: 26px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .preview_title {
    justify-content: center;
    cursor: pointer;
    .tip1 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 22px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      margin-top: 4px;
      font-size: 22px;
      color: #333333;
    }
    .tip {
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .tip2 {
      font-size: 40px;
      color: #333333;
      font-weight: 500;
      text-align: center;
      height: 50px;
      line-height: 50px;
    }
    .tip3 {
      font-size: 20px;
      color: #333333;
      font-weight: 500;
      text-align: center;
      margin-left: 4px;
      height: 50px;
      line-height: 58px;
    }
  }
  .divider {
    height: 1px;
    border: 1px #d6d6da;
    background: #d6d6da;
    margin-top: 18px;
  }
  .send-status {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 28px;
    line-height: 45px;
    height: 45px;
    .status {
      height: 18px;
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      text-align: center;
      margin-right: 6px;
    }
    .status-time {
      display: flex;
      align-items: center;
      margin-right: 4px;
      width: 78px;
      height: 24px;
      background: #3d7fff;
      border-radius: 2px;
    }
    .status1 {
      height: 20px;
      font-weight: 400;
      color: #ffffff;
      font-size: 12px;
      line-height: 20px;
    }
    .status2 {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
  }
}
.content-item:hover {
  box-shadow: 0px 3px 10px -4px rgba(37, 55, 98, 0.5),
    0px 6px 18px 8px rgba(21, 27, 41, 0.05);
}
</style>
