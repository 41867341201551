import gz from './gz';
import jz from './jz';
import auth from'./auth';
import j2 from './j2';
import cst from './cst';
import erp from './erp'

export default {
  gzApi: gz,
  jzApi: jz,
  authApi: auth,
  j2Api: j2,
  cstApi: cst,
  erpApi: erp,
}
