<template>
  <div class="contents salary-send-modify">
    <!-- <div style="margin-left:4px;">
      <img src="../../assets/phone-head.png" style="width: 100%;"/>
    </div> -->
    <div class="preview_head">
      <div class="title" v-if="flag">
        {{ payDateTitle }}月-{{ previewData.previewTitle.salaryTypeName }}
      </div>
    </div>
    <div class="preview_title">
      <div class="tip1">实发工资(元)</div>
      <div style="display: flex; align-items: center; justify-content: center">
        <el-input
          class="tip2"
          placeholder="0.00"
          v-model="input"
          size="mini"
          @change="inputtitleChange"
        ></el-input>
      </div>
      <div class="tip3" v-if="flag">
        {{ previewData.previewTitle.companyName }}
      </div>
    </div>
    <div class="preview_body scroll">
      <div class="company-info-item">
        <div class="tip"></div>
        <div class="tip_lable">工资明细</div>
      </div>
      <div
        class="company-info-item"
        style="justify-content: space-between; align-items: center"
        v-for="(item, i) in previewDataItems"
        :key="i"
      >
        <div class="tip4">{{ item.itemName }}</div>

        <div>
          <el-input
            class="tip-input"
            ref="input"
            @blur="item.isEdit = false"
            :autofocus="!i"
            v-show="item.isEdit"
            v-model="item.itemValue"
            @change="inputChange"
            @focus="showTip(item)"
            :readonly="
              /^(0|86|17951)?(10|13|14|15|16|17|18|19)[0-9][0-9]{8}$/.test(
                item.itemValue
              )
            "
          ></el-input>
          <div
            class="tip-input-text"
            v-if="!item.isEdit"
            @click="showEdit(item, i)"
          >
            {{ item.itemValue }}
          </div>
        </div>
      </div>
      <div class="announcement" v-if="previewData.announcement">
        公告： {{ previewData.announcement }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "salary-send-modify-componet",
  props: ["previewData"],
  components: {},
  data() {
    return {
      payDateTitle: 0,
      previewDataList: [],
      previewDataItems: [],
      value: "100",
      input: 0,
      flag: false,
      firstIn: false,
    };
  },
  watch: {
    previewData(previewData) {
      if (previewData) {
        this.previewDataList = this.previewData;
        this.praseDate(this.previewDataList.previewTitle.payDate);
        this.previewDataItems = [...this.previewDataList.previewItems].map(
          (x, i) => ({ ...x, isEdit: !i })
        );
        this.firstIn = true;
        this.input = this.previewDataList.previewTitle.takeHomePay;
      }
    },
  },
  created() {},
  updated() {
    if (this.firstIn) {
      this.firstIn = false;
      const index = this.previewDataItems.findIndex((x) => x.isEdit);
      this.showEdit(this.previewDataItems[index], index);
    }
  },
  methods: {
    showTip(item) {
      const regExp = /^(0|86|17951)?(10|13|14|15|16|17|18|19)[0-9][0-9]{8}$/;
      if (regExp.test(item.itemValue)) {
        this.$message.error("手机号不允许修改");
      }
    },
    praseDate(time) {
      let date = new Date(Number(time));
      this.payDateTitle = date.getMonth() + 1;
      this.flag = true;
    },

    inputChange() {
      let inputBean = this.previewDataItems.find(
        (x) => x.itemId == this.previewDataList.previewTitle.takeHomePayItemId
      );

      if (inputBean) {
        this.input = inputBean.itemValue;
      }

      this.$emit("modifySalary", this.previewDataItems);
    },

    inputtitleChange() {
      let inputBean = this.previewDataItems.find(
        (x) => x.itemId == this.previewDataList.previewTitle.takeHomePayItemId
      );
      inputBean.itemValue = this.input;
      this.$emit("modifySalary", this.previewDataItems);
    },

    showEdit(item, index) {
      if (!item) {
        return;
      }

      if (index) {
        this.previewDataItems[0].isEdit = false;
      }

      item.isEdit = true;
      setTimeout(() => {
        this.$refs.input &&
          this.$refs.input[index] &&
          this.$refs.input[index].focus();
      }, 100);
    },
  },
};
</script>

<style scoped lang="scss">
.announcement {
  color: #666666;
  font-size: 12px;
  margin: 14px;
}
.contents {
  width: 266px;
  overflow: hidden;
  box-shadow: 0px 8px 18px 0px rgba(104, 113, 168, 0.28);
  border-radius: 8px;
  border: 1px solid #ebebeb;

  .preview_head {
    display: flex;
    width: 100%;
    margin-top: 14px;
    margin-bottom: 16px;
    align-items: center;
    justify-content: center;
  }
  .title {
    width: 30%;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #2a2d3f;
    line-height: 17px;
  }
}
.preview_title {
  width: 246px;
  height: 128px;
  margin: 10px;
  border-radius: 8px;
  background-size: 100% 100%;
  background-image: url(../../assets/salary-bg.png);

  .tip1 {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 17px;
    text-align: center;
    padding-top: 20px;
  }
  .tip2 {
    margin-top: 8px;
  }
  /deep/.el-input--mini {
    display: flex;
    align-items: center;
    justify-content: center;

    .el-input__inner {
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      line-height: 28px;
      text-align: center;
      margin-top: 2px;
      width: 120px;
      text-align: center;
      height: 28px;
      text-align: left;
      background: transparent;
    }
  }
  .tip3 {
    font-size: 12px;
    font-weight: 400;
    color: #dae0fe;
    line-height: 17px;
    text-align: center;
    margin-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
  }
}
.tip-input {
  width: 130px;
  text-align: left;
  font-size: 14px;
  color: #030303;
}

.tip-input-text {
  text-align: left;
  font-size: 14px;
  color: #030303;
  width: 130px;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 5px;
  box-sizing: border-box;
  height: 28px;
  line-height: 28px;
  border: 1px solid #fff;
  border-radius: 3px;

  &:hover {
    border-color: #999;
  }
}

.salary-send-modify /deep/.el-input__inner {
  padding: 0 5px !important;
}

/deep/.el-input__inner {
  width: 130px;
  height: 28px;
}

.preview_body {
  overflow-y: auto;
  height: 312px;

  .company-info-item {
    height: 40px;
    align-items: center;
    display: flex;
    margin-left: 14px;
    margin-right: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    // margin-top: 26px;
    .tip {
      width: 3px;
      height: 10px;
      background: #465df9;
      border-radius: 2px;
      margin-left: 14px;
    }
    .tip_lable {
      margin-left: 6px;
      height: 17px;
      font-size: 12px;
      font-weight: 500;
      color: #030303;
      line-height: 17px;
    }
  }
  .tip4 {
    height: 17px;
    font-size: 12px;
    color: #030303;
    line-height: 17px;
  }
}
</style>
