import VueRouter from 'vue-router';
import Vue from 'vue';

VueRouter.prototype.push = (function (push) {
  return function (options) {
    return push.call(this, options).catch(err => err)
  }
})(VueRouter.prototype.push)

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: '/salary'},
  {
    // { path: '/user/:id', component: User }
    path: '/salary',
    component: () => import('../components/salary.vue'),
    children: [
      { path: '', redirect: 'fristpage'},
      { path: 'companyinfo', component: () => import('../components/salary-newbuild/salary-companyinfo.vue') },
      { path: 'newbuild', component: () => import('../components/salary-newbuild/salary-newbuild.vue') },
      { path: 'sendrecord', component: () => import('../components/salary-sendrecord/salary-sendrecord.vue') },
      { path: 'fristpage', component: () => import('../components/salary-frist/salary-frist.vue') },
      { path: 'sendrecorddetail', component: () => import('../components/salary-sendrecord/salary-senddetail.vue') },
      { path: 'sendrecordcheck', component: () => import('../components/salary-sendrecord/salary-sendcheck.vue') },
      { path: 'sendrecordmodify', component: () => import('../components/salary-sendrecord/salary-send-modify.vue') },
      { path: 'companylist', component: () => import('../components/salary-newbuild/salary-company-list.vue') },
      { path: 'personalseting', component: () => import('../components/setting/personal-setting.vue') },
      {path: 'migrate', component: () => import('../components/salary-newbuild/salary-migrate.vue')},
      { path: 'test', component: () => import('../components/test.vue') },
    ]
  },
];

export default new VueRouter({
  routes,
  mode: 'history'
});
