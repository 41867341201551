export const environment = {
  gzApi: process.env['VUE_APP_GZ_API'],
  jzApi: process.env['VUE_APP_JZ_API'],
  dzApi: process.env['VUE_APP_DZ_API'],
  about: process.env['VUE_APP_ABOUT'],
  stud: process.env['VUE_APP_STUD'],
  authApi: process.env['VUE_APP_AUTH'],
  j2Api:process.env['VUE_APP_j2'],
  gwApi:process.env['VUE_APP_GW'],
  gw:process.env['VUE_APP_GW'],
  cstApi: process.env['VUE_APP_CST_API'],
  cstFront: process.env['VUE_APP_CST_FRONT'],
  cstLogin: process.env['VUE_APP_CST_LOGIN'],
  cstPcApi: process.env['VUE_APP_CST_PC_API'],
  cstJzApi: process.env['VUE_APP_CST_JZ_API'],
  ycApi: process.env['VUE_APP_YC_API'],
  ycFront: process.env['VUE_APP_YC_FRONT'],
  erp: process.env['VUE_APP_ERP_API'],
  jzProApi: process.env['VUE_APP_JZPRO_API']
}
