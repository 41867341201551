import Vue from 'vue';

// 全局组件
import salaryTypeConfirmDialog from './components/salary-newbuild/salary-settype-dialog.vue';
import salaryUploadDialog from './components/salary-newbuild/salary-upload-dialog.vue';
import commMenu from './components/common/comm-menu.vue';
import salaryExistCompany from './components/salary-newbuild/salary-existcompany.vue';
import salarySelfDefined from './components/salary-newbuild/salary-selfdefined.vue';
import salaryNewBuildHeader from './components/salary-newbuild/salary-newbuild-header.vue';
import salaryCheckData from './components/salary-newbuild/salary-checkdata.vue';
import salaryPreviewSend from './components/salary-newbuild/salary-previewsend.vue';
import commonDialog from './components/common/common-dialog.vue';
import salarySendResult from './components/salary-newbuild/salary-sendresult.vue';
import salaryPreview from './components/salary-newbuild/salary-preview.vue';
import salarySendRecordItem from './components/salary-sendrecord/salary-sendrecord-item.vue';
import salarySendModifyCom from './components/salary-sendrecord/salary-send-modify-componet.vue';
import salaryUpload from './components/salary-newbuild/salary-upload.vue';
import salarySendDialog from './components/salary-newbuild/salary-send-dialog.vue';
import pager from './components/common/pager.vue';
import companyLable from './components/common/inputCompanyLabel.vue';
export default function () {

  Vue.component('salary-settype-dialog', salaryTypeConfirmDialog);
  Vue.component('salary-upload-dialog', salaryUploadDialog);
  Vue.component('comm-menu',commMenu);
  Vue.component('salary-existcompany',salaryExistCompany);
  Vue.component('salary-selfdefined',salarySelfDefined);
  Vue.component('salary-newbuild-header',salaryNewBuildHeader);
  Vue.component('salary-checkdata',salaryCheckData);
  Vue.component('salary-previewsend',salaryPreviewSend);
  Vue.component('common-dialog',commonDialog);
  Vue.component('salary-sendresult',salarySendResult);
  Vue.component('salary-preview',salaryPreview);
  Vue.component('salary-sendrecord-item',salarySendRecordItem);
  Vue.component('salary-send-modify-componet',salarySendModifyCom);
  Vue.component('salaryupload',salaryUpload);
  Vue.component('salary-send-dialog',salarySendDialog);
  Vue.component('pager',pager);
  Vue.component('inputCompanyLabel',companyLable);
}
