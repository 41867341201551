import j2ApiInstance from '@/axios/interceptor/j2';

// 
function checkBind() {
  return j2ApiInstance.post(`/Api/WechatBindProcess.ashx?type=isBind`);
}
// 
function ChangeName(nick) {
  return j2ApiInstance.post(`/Default/Services/ChangeNickName.ashx?nick=${nick}`);
}
// { mobile: self.userInfo.Mobile, code: self.oldPassword }
function verifyPass(mobile,code) {
  return j2ApiInstance.post(`/Default/Services/SendSMSForVerify.ashx?type=2&mobile=${mobile}&code=${code}`);
}

function SavePass(CurrentPassword,NewPassword) {
  return j2ApiInstance.get(`/Api/SubmitForNewPassword.ashx?CurrentPassword=${CurrentPassword}&NewPassword=${NewPassword}`);
}
function SaveMobile(Mobile,ConfirmCode) {
  return j2ApiInstance.post(`/Default/Services/SubmitForPersonalInfo.ashx?Mobile=${Mobile}&ConfirmCode=${ConfirmCode}&EditType="Mobile"`);
}
function WxOrToken() {
  return j2ApiInstance.post(`/Api/WxQrToken.ashx`);
}
function WechatChangeScaned(sceneId){
  return j2ApiInstance.get(`/Api/WechatChangeScaned.ashx?SceneId=${sceneId}`);
}
function WechatBindScaned(sceneId){
  return j2ApiInstance.get(`/Api/WechatBindScaned.ashx?SceneId=${sceneId}`);
}
// 
function logout() {
  return j2ApiInstance.post(`/api/logout.ashx`);
}
export default {
  checkBind,
  ChangeName,
  verifyPass,
  SavePass,
  SaveMobile,
  WxOrToken,
  WechatChangeScaned,
  WechatBindScaned,
  logout
}

