<template>
  <div class="contents-preview">
    <!-- <div style="margin-left:10px;">
      <img src="../../assets/phone-head.png" style="width: 100%"/>
    </div> -->
    <div class="preview_head">
      <div class="title">
        {{ payDate }}月-{{ previewData.previewTitle.salaryTypeName }}
      </div>
    </div>
    <div class="preview_title" v-if="flag">
      <div class="tip1">实发工资(元)</div>
      <div class="tip2">
        {{ moneyParse(previewData.previewTitle.takeHomePay) }}
      </div>
      <div class="tip3">{{ previewData.previewTitle.companyName }}</div>
    </div>
    <div class="preview_body scroll">
      <div class="company-info-item1">
        <div class="tip"></div>
        <div class="tip_lable">工资明细</div>
      </div>
      <div
        class="company-info-item"
        style="justify-content: space-between"
        v-for="(item, i) in previewDataItems"
        :key="i"
      >
        <div class="tip4">{{ item.itemName }}</div>
        <div class="tip4">{{ item.itemValue }}</div>
      </div>
      <div class="announcement" v-if="isshowannouncement">
        公告： {{ announcement }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "salary-preview",
  components: {},
  props: [
    "salaryItem",
    "previewData",
    "showNullColumn",
    "isshowannouncement",
    "announcement",
  ],
  data() {
    return {
      payDate: 0,
      companyName: 0,
      previewDataItems: [],
      previewDataList: [],
      salaryItemList: [],
      flag: false,
      eeee: [],
    };
  },
  watch: {
    salaryItem(salaryItem) {
      if (salaryItem) {
        this.makeData();
      }
    },
    previewData(previewData) {
      if (previewData.previewItems) {
        this.flag = true;
        this.makeData();
        this.praseDate();
      }
    },
    showNullColumn() {
      this.makeData();
    },
  },
  created() {},
  mounted() {},
  methods: {
    moneyParse(value) {
      // 金额 格式化
      if (!value && value !== 0) return "-";
      var intPart = Number(value) || 0;
      //  | 0; //获取整数部分
      var intPartFormat = intPart
        .toFixed(2)
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); //将整数部分逢三一断 8,695.00

      return intPartFormat;
    },
    makeData() {
      if (this.previewData.previewItems) {
        this.previewDataItems = [].concat(this.previewData.previewItems);
      }
      if (this.salaryItem) {
        this.salaryItemList = [].concat(this.salaryItem);
      }

      // 隐藏数据 salaryItem含有的项在 this.previewDataItems进行删除，进 行展示
      let isHide = null;
      this.previewDataItems = this.previewDataItems.filter((x) => {
        isHide = this.salaryItemList.find((y) => y == x.itemName);
        return !isHide;
      });

      // 是否显示空值
      if (!this.showNullColumn) {
        // 不显示空值
        this.previewDataItems = this.previewDataItems.filter((x) => {
          return !!x.itemValue;
        });
      }
    },
    praseDate() {
      let tem = this.previewData.previewTitle.payDate;
      let date = new Date(tem);
      this.payDate = date.getMonth() + 1;
    },
  },
};
</script>

<style scoped lang="scss">
.announcement {
  color: #666666;
  font-size: 12px;
  margin: 14px;
}
.contents-preview {
  width: 266px;
  overflow: hidden;

  box-shadow: 0px 8px 18px 0px rgba(104, 113, 168, 0.28);
  border-radius: 8px;
  border: 1px solid #ebebeb;

  .preview_head {
    display: flex;
    width: 100%;
    margin-top: 14px;
    margin-bottom: 16px;
    align-items: center;
    justify-content: center;
  }
  .title {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #2a2d3f;
    line-height: 17px;
  }
}
.preview_title {
  width: 246px;
  height: 128px;
  // background: #5771fe;
  margin: 10px;
  background-size: 100% 100%;
  background-image: url(../../assets/salary-bg.png);
  border-radius: 8px;
  // border: 1px solid#5771FE;
  justify-content: center;
  .tip1 {
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 17px;
    // margin-top: 25px;
    text-align: center;
    padding-top: 20px;
  }
  .tip2 {
    height: 28px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    line-height: 28px;
    text-align: center;
    margin-top: 6px;
  }
  .tip3 {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    color: #f2f3fc;
    margin-bottom: 10px;
  }
}

.preview_body {
  overflow-y: auto;
  height: 312px;
  .company-info-item1 {
    align-items: center;
    display: flex;
    margin-top: 26px;
    margin-bottom: 10px;
  }
  .company-info-item {
    height: 40px;
    align-items: center;
    display: flex;
    margin-left: 14px;
    margin-right: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    // padding: 10px 10px;
  }
  .tip {
    width: 3px;
    height: 10px;
    background: #465df9;
    border-radius: 2px;
    margin-left: 14px;
  }
  .tip_lable {
    margin-left: 6px;
    height: 17px;
    font-size: 12px;
    font-weight: 500;
    color: #030303;
    line-height: 17px;
  }
  .tip4 {
    height: 17px;
    font-size: 12px;
    color: #030303;
    line-height: 17px;
    // margin-left: 10px;
    // margin-right: 10px;
  }
}
</style>
