import axios from 'axios';
import axiosRetry from 'axios-retry';
import {environment} from '@/environment';
import { isCstGzt } from '../../../common/utils';

const authApiInstance = axios.create({
  baseURL: environment.authApi,
  timeout: 30000
});


let pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let cancelToken = axios.CancelToken;
let removePending = config => {
  for (let p in pending) {
    if (pending[p].u === config.url + '&' + config.method + '&' + config.data) { //当当前请求在数组中存在时执行函数体
      pending[p].f('重复请求'); //执行取消操作
      pending.splice(p, 1); //把这条记录从数组中移除
    }
  }
}

axiosRetry(authApiInstance, {retry: 3});


authApiInstance.interceptors.request
  .use(config => {
    config.withCredentials = true;
    // 判断 cookie 是否存在

    return config;
  });

authApiInstance.interceptors.request.use(config => {
  removePending(config);
  config.cancelToken = new cancelToken((c)=>{
    pending.push({ u: config.url + '&' + config.method + '&' + config.data, f: c });
  });
  return config;
}, error => {
  return Promise.reject(error)

})

authApiInstance.interceptors.response.use(res => {
  // console.log(res)
  removePending(res.config);
  return res;
}, e => {
  // console.log(e);
  // 判断 请求返回值 401
  if (e.response && e.response.status == 401 && location.host.indexOf('localhost') == -1) {
    if(isCstGzt()){//如果是财税通的工资条
      location.href = environment.cstLogin+"cst/default.aspx";
    }else{
      location.href = environment.gwApi+"salary.aspx";
    }
  }

  return Promise.reject(e);
});



export default authApiInstance;
