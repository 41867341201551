<template>
  <div class="content">
    <div class="progress" >
      <div class="title">
        <div class="devider"></div>
        <div class="title-name">新建工资条</div>
      </div>

      <div class="flex align-center justify-center">
        <div class="progress-one flex align-center">
          <img :src="index === 1 ? progress_done_1:(index > 1 ? progress_havedone:progress2)" />
          <div :class="index ===1 ? 'progress-tip-done' :'progress-tip'">上传工资表</div>
          <div class="progress-devider"></div>
        </div>

        <div class="progress-one flex align-center">
          <img :src="index == 2 ? progress_done_2:(index > 2 ? progress_havedone:progress2)" />
          <div :class="index ===2 ? 'progress-tip-done' :'progress-tip'">核对数据</div>
           <div class="progress-devider"></div>
        </div>

        <div class="progress-one flex align-center">
          <img :src="index === 3 ? progress_done_3:(index > 3 ? progress_havedone:progress3)" />
          <div :class="index ===3 ? 'progress-tip-done' :'progress-tip'">预览发送</div>
          <div class="progress-devider"></div>
        </div>

        <div class="progress-one flex align-center">
          <img :src="index === 4 ? progress_done_4:(index > 4 ? progress_havedone:progress4)" />
          <div :class="index ===4 ? 'progress-tip-done' :'progress-tip'">发送结果</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "salary-newbuild-header",
  props: ["index"],
  components: {},
  data() {
    return {
      progress_done_1: require("@/assets/progress-one-done.png"),
      progress_done_2: require("@/assets/progress-two-done.png"),
      progress_done_3: require("@/assets/progress-three-done.png"),
      progress_done_4: require("@/assets/progress-four-done.png"),
      progress2: require("@/assets/progress-two.png"),
      progress3: require("@/assets/progress-three.png"),
      progress4: require("@/assets/progress-four.png"),
      progress_havedone: require("@/assets/upload-havedone.png")
    };
  },
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style scoped lang="scss">
.progress {
  align-items: center;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(17, 31, 65, 0.1);
  border-radius: 8px 8px 0px 0px;
  padding-bottom: 30px;
  padding-top: 16px;
}
.title {
  display: flex;
  padding-top: 4px;
  align-items: center;
  margin-bottom: 30px;
}
.title-name {
  height: 22px;
  font-size:18px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
  margin-left: 6px;
}
.content {
  margin-top: 20px;
  width: 1240px;
}
.devider {
  margin-left: 30px;
  width: 4px;
  height: 16px;
  background: linear-gradient(150deg, #79c6ff 0%, #4f99f5 44%, #2747dc 100%);
}
.progress-tip-done {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin-left: 10px;
}

.progress-one {
  img {
    width: 36px;
    height: 36px;
  }
}
.progress-tip {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
color: #666666;
  line-height: 20px;
  margin-left: 10px;
}
.progress-devider {
  width: 64px;
  height: 1px;
background: #E6E9ED;
  border-radius: 1px;
  margin-left: 16px;
  margin-right: 16px;
}

.progress-devider-done {
  width: 64px;
  height: 2px;
  background: #455bf7;
  border-radius: 3px;
  margin-left: 16px;
  margin-right: 16px;
}
</style>
