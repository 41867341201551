import { Message } from 'element-ui';

let messageInstance = null;
const singleMessage = (options) => {
  if (messageInstance) {
    messageInstance.close()
  }
  if (options.type === 'warning') {
    options.customClass = 'warning-alert-reset-style';
  }
  options.customClass = 'common-alert-reset-style';
  messageInstance = Message(options)
};
['error', 'success', 'info', 'warning'].forEach(type => {
  singleMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
      }
    }
    options.type = type;
    if (options.type === 'warning') {
      options.customClass = 'warning-alert-reset-style';
    }
    options.customClass = 'common-alert-reset-style';
    return singleMessage(options)
  }
})

const resetMessage = {
  install(Vue) {
    Vue.prototype.$message = singleMessage;
    // Vue.prototype.$singleMessage = singleMessage;
  }
}

export default resetMessage;
