const mixIn = {
  install(Vue) {
    Vue.mixin({
      methods: {
        download(data, name) {
          var url = data;
          var self = this;
          // 通过地址判断是否为图片类型文件
          this.convertUrlToBase64(url).then(function (base64) {
            var blob = self.convertBase64UrlToBlob(base64);

            // 下载
            if (self.myBrowser() == 'IE') {
              window.navigator.msSaveBlob(blob, name);
            } else {
              var a = document.createElement('a');
              a.download = name;
              a.href = URL.createObjectURL(blob);
              a.click();
            }
          });
        },
    
        myBrowser() {
          var userAgent = navigator.userAgent; 
          if (userAgent.indexOf("OPR") > -1) {
            return "Opera";
          }
          if (userAgent.indexOf("Firefox") > -1) {
            return "FF";
          } 
          if (userAgent.indexOf("Trident") > -1) {
            return "IE";
          } 
          if (userAgent.indexOf("Edge") > -1) {
            return "Edge";
          } 
          if (userAgent.indexOf("Chrome") > -1) {
            return "Chrome";
          } 
          if (userAgent.indexOf("Safari") > -1) {
            return "Safari";
          } 
        },
    
        convertBase64UrlToBlob(base64) {
          var parts = base64.dataURL.split(';base64,');
          var contentType = parts[0].split(':')[1];
          var raw = window.atob(parts[1]);
          var rawLength = raw.length;
          var uInt8Array = new Uint8Array(rawLength);
          for (var i = 0; i < rawLength; i++) {
            uInt8Array[i] = raw.charCodeAt(i);
          }
          return new Blob([uInt8Array], { type: contentType });
        },
    
        convertUrlToBase64(url) {
          return new Promise(function (resolve) {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = url;
            img.onload = function () {
              var canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
              var ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, img.width, img.height);
              var ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
              var dataURL = canvas.toDataURL('image/' + ext);
              var base64 = {
                dataURL: dataURL,
                type: 'image/' + ext,
                ext: ext
              };
              resolve(base64);
            };
          });
        },

        getCompanyList(reset = false) {
          if (reset) {
            this.$store.commit('$setCompanyList', []);
          }
          
          return this.$store.getters.store_companyList.then((list) => {
            this.$store.commit('$setCompanyList', list);
            return list || [];
          });
        },

        setCompanyByAsid(asid) {
          const self = this;
          let companyList = [];
          let company = null;
          return self.getCompanyList().then((list) => {
            companyList = list;

            // 获取账套列表
            return self.$store.getters.store_accountList
          }).then((accountList) => {
            console.log('获取到账套列表：', accountList)
            const itemTemp = accountList.find(x => x.as_id == asid);
            if (itemTemp) {
              company = companyList.find(x => x.companyName == itemTemp.as_name);
            }

            if (company) {
              console.log('我在执行setCompanyByAsid')
              self.$store.dispatch("store_SetCompanyId", company.companyId);
              self.$store.dispatch("store_SetCompanyName", company.companyName); 
            }
            return company || null;
          });
        },
        fmtDate (date, fmt) {
          date = new Date(date);
        
          if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
          }
          let o = {
            'M+': date.getMonth() + 1,
            'd+': date.getDate(),
            'H+': date.getHours(),
            'm+': date.getMinutes(),
            's+': date.getSeconds()
          }
          for (let k in o) {
            if (new RegExp(`(${k})`).test(fmt)) {
              let str = o[k] + ''
              fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str))
            }
          }
          return fmt
        }
      },
    });
  }
}

function padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

export default mixIn;
