<template>
  <div class="content-checkdata">
    <div class="devider"></div>
    <div class="company-info-tip">
      <div class="circle"></div>
      <div class="tip1">系统已智能识别到</div>
      <div class="tip2">{{tableList.length}}</div>
      <div class="tip1">条工资条记录</div>
      <template v-if="excelDetail.errorDataCount">
        <img src="../../assets/salary-tip.png" style="margin-top: 20px;margin-left:65px;" />
        <div class="tip1">识别失败无法发送工资条：</div>
        <div class="tip2">{{excelDetail.errorDataCount}}</div>
        <div class="tip1">条</div>
        <div class="tip2" style="margin-left:20px;cursor:pointer;" @click="checkDownload">下载</div>
      </template>
    </div>

    <div class="table">
      <el-table :data="tableList.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)" height="calc(100vh - 524px)" border style="width: 100%; min-height: 150px">
        <el-table-column :label="item" v-for="(item, i) in tableHeader " :key="i" :min-width="wd(item, i)">
          <template slot-scope="scope">
            <div><span>{{ scope.row[i] }}</span></div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="height:40px; padding: 0 11px;">
      <pager :total="tableList.length" :page-index="pageIndex" @page-change="pageChange"></pager>
    </div>
    <div class="body-foot">
      <div class="body-foot-tip">
        <img
          style=" width:18px;height:18px; margin-right:4px;"
          src="../../assets/salary-checkdata-tip.png"
        />
        <div>智能匹配工资数据</div>
        <!-- &emsp;<span style="font-size:12px;color: #000;font-weight:bold;">注意：员工姓名过长时仅显示前4个字</span> -->
      </div>
      <div class="company-info-item flex">
        <div class="tip3" style="margin-left:30px;">*</div>
        <div class="label">员工姓名:</div>
        <el-select popper-class="select-common-color" :placeholder="null" @input="selectNameChange" v-model="itemNameId" >
          <el-option v-for="item in excelTitles" :key="item.itemId" :label="item.itemName" :value="item.itemId" ></el-option>
        </el-select>

        <div class="tip3">*</div>
        <div class="label">手机号:</div>
        <el-select popper-class="select-common-color" :placeholder="null" @input="selectPhoneChange" v-model="itemPhoneId" >
          <el-option v-for="item in excelTitles" :key="item.itemId" :label="item.itemName" :value="item.itemId" ></el-option>
        </el-select>

        <div class="tip3">*</div>
        <div class="label">实发工资:</div>
        <el-select popper-class="select-common-color" :placeholder="null" @input="selectSalaryChange" v-model="itemSalaryId" > 
          <el-option v-for="item in excelTitles" :key="item.itemId" :label="item.itemName" :value="item.itemId" ></el-option>
        </el-select>

        <div class="label" style="margin-left:60px;">邮箱:</div>
        <el-select popper-class="select-common-color" :class="{'clear-icon': itemEmailId}" :placeholder="null" @input="selectEmailChange" v-model="itemEmailId" :clearable="true">
          <el-option v-for="item in excelTitles" :key="item.itemId" :label="item.itemName" :value="item.itemId"></el-option>
        </el-select>
      </div>
    </div>

    <div class="footer-btn">
      <div style="width: 120px" class="salary-button default" @click="reUpload">重新上传</div>
      <div style="width: 120px" class="salary-button primary" @click="next">下一步</div>
    </div>
  </div>
</template>

<script>
import exportFile from "@/scripts/fileserve.js";

export default {
  name: "salary-checkdata",
  props: ["excelDetail"],
  components: {},
  data() {
    return {
      pageIndex: 0,
      pageSize: 300,
      excelTitles: [],
      itemNameId: null,
      itemPhoneId: null,
      itemEmailId: null,
      itemSalaryId: null,
      nameFlag: false,
      PhoneFlag: false,
      emailFlag: false,
      salaryFlag: false,
      checkType: 0, // 1010 姓名 / 	1020 手机号 /1030 邮箱 / 1040 实发工资
      checkParams: {
        companyId: 0,
        salaryId: 0,
        itemId: 0,
        checkType: 0
      },
      companyId: 0,
      tableHeader: [],
      tableList: [],
      employeeSn: 0,
      employeeSnList: [],
      previewData: [],
      recordNum: 0
    };
  },
  created() {
    this.companyId = Number(this.$store.getters.store_comanyId);
    this.excelTitles = this.excelDetail.excelTitles;
    this.employeeSn = this.excelDetail.excelDetailInfos[1].employeeSn;
  
    this.checkParams.companyId = this.companyId;
    this.checkParams.salaryId = this.excelDetail.salaryId;

    this.tableHeader.push("序号");
    const list = eval(this.excelDetail.excelDetailInfos[0].excelDetail);
    this.tableHeader = this.tableHeader.concat(list || []);

    this.tableList = (this.excelDetail.excelDetailInfos || []).filter((x, i) => !!i).map((x, i) => ([i + 1].concat(eval(x.excelDetail) || [])))

    // 初始化对应状态
    this.excelDetail.excelTitleMaps.filter(x => {
      switch(x.itemType) {
      case 1010:
        this.nameFlag = !!x.itemId
        this.itemNameId = x.itemId || null
        break;
      case 1020:
        this.PhoneFlag = !!x.itemId
        this.itemPhoneId = x.itemId || null
        break;
      case 1040:
        this.salaryFlag = !!x.itemId
        this.itemSalaryId = x.itemId || null
        break;
      case 1030:
        this.emailFlag = !!x.itemId
        this.itemEmailId = x.itemId || null
        break;
      }
    })
  },
  mounted() {},
  methods: {
    wd(item, index) {
      if (item == "手机号") {
        return "190px";
      } else if (item == "身份证号") {
        return "210px";
      } else if (item == "邮箱") {
        return "210px";
      } else if (item == "住房公积金" || item == "个税免征额") {
        return "99px";
      } else if (
        item == "其他税后调整" ||
        item == "代扣个人款项" ||
        item == "本月应缴个税"
      ) {
        return "106px";
      } else {
        let length = 0;
        this.tableList.filter(x => {
          if (x && x[index] && x[index].length > length) {
            length = x[index].length
          }
        })

        if (length > 15) {
          return length * 16 + "px";
        } else if (length > 8) {
          return "144px";
        } else {
          return "144px";
        }
      }
    },
    pageChange(pageIndex, pageSize) {
      this.pageIndex = pageIndex;
      this.pageSize = pageSize;
    },
    checkDownload() {
      // 下载
      this.$axios.gzApi.SalaryTemplatePost({
        salaryId: this.excelDetail.salaryId,
        companyId: this.companyId
      }).then(res => {
        if (res && res.data) {
          exportFile.getExcel(res.data, "工资条错误数据.xlsx");
        }
      });
    },
    selectNameChange(index) {
      this.checkParams.itemId = index;
      this.checkParams.checkType = 1010;
      this.checkType = 1010;
      this.SalaryCheckPost(this.checkParams);
    },
    selectPhoneChange(index) {
      this.checkParams.itemId = index;
      this.checkParams.checkType = 1020;
      this.checkType = 1020;
      this.SalaryCheckPost(this.checkParams);
    },
    selectEmailChange(index) {
      this.checkParams.itemId = index;
      this.checkParams.checkType = 1030;
      this.checkType = 1030;
      this.SalaryCheckPost(this.checkParams);
    },
    selectSalaryChange(index) {
      this.checkParams.itemId = index;
      this.checkParams.checkType = 1040;
      this.checkType = 1040;
      this.SalaryCheckPost(this.checkParams);
    },
    SalaryCheckPost(params) {
      this.$axios.gzApi.SalaryCheckPost(params).then(res => {
        let flag = false;
        if (res) {
          if (res.data.code === 1000 && res.data.subCode === 0) {
            //成功
            flag = true;
          } else if (res.data.code === 9999 && res.data.subCode === 1003) {
            // 不成功
            this.$message({
              message: res.data.msg,
              type: "warning",
              offset: 90
            });
            flag = false;
          }
        }

        if (this.checkType === 1010) {
          this.nameFlag = flag;
        } else if (this.checkType === 1020) {
          this.PhoneFlag = flag;
        } else if (this.checkType === 1030) {
          this.emailFlag = flag;
        } else if (this.checkType === 1040) {
          this.salaryFlag = flag;
        }
      });
    },
    next() {
      let msgArr = [];
      if (!this.nameFlag) {
        msgArr.push('员工姓名');
      }

      
      if (!this.PhoneFlag) {
        msgArr.push('手机号');
      }

      
      if (!this.salaryFlag) {
        msgArr.push('实发工资');
      }

      if (this.itemEmailId && !(this.itemEmailId && this.emailFlag)) {
        msgArr.push('邮箱');
      }

      if (msgArr.length) {
        this.$message({
          message: "请匹配" + msgArr.join('，'),
          type: "warning",
          offset: 90
        });
      } else {
        this.$store.dispatch("store_settotalNumber", this.tableList.length);
        this.$emit("previewMethod", this.employeeSn, 2);
      }

      // if (
      //   this.nameFlag &&
      //   this.PhoneFlag &&
      //   this.salaryFlag && (
      //     !this.itemEmailId || (this.itemEmailId && this.emailFlag)
      //   )
      // ) {
      //   this.$store.dispatch("store_settotalNumber", this.tableList.length);
      //   this.$emit("previewMethod", this.employeeSn, 2);
      // } else {
      //   this.$message({
      //     message: "数据校验不成功，重新校验！",
      //     type: "warning",
      //     offset: 90
      //   });
      // }
    },
    SalaryEmployeeGet() {
      this.$axios.gzApi
        .SalaryEmployeeGet(this.companyId, this.excelDetail.salaryId)
        .then(res => {
          if (res.data) {
            this.employeeSnList = res.data.data;
            this.employeeSn = this.employeeSnList[0].employeeSn;
          }
        });
    },
    reUpload() {
      this.$emit("reUpload", 1);
    }
  }
};
</script>

<style scoped lang="scss">
/deep/.el-input__inner {
  background: #ffffff;
  // border-radius: 2px;
  border: 1px solid #d6d6da;
  // width: 350px;
  height: 36px;
  padding-left: 15px;
  cursor: default;
}
/deep/.el-input__inner:hover {
  border: 1px solid#888888;
  background: #ffffff;
}
/deep/.el-input__inner:focus {
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #3d7fff;
}
/deep/.el-table__row:focus {
  background: #f5f9ff;
}
/deep/.el-table__row:hover {
  background: #edf4ff;
}
/deep/.cell {
  font-size: 14px;
  color: #333333;
}
/deep/.is-leaf {
  background: #f5f7fa;
}
.body-foot {
  margin-left: 20px;
  margin-right: 23px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(31, 50, 98, 0.12);
  border-radius: 3px;
  border: 1px solid #E1EAF7;
  padding-bottom: 24px;
  
  .body-foot-tip {
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
  .tip3 {
    color: #f6404e;
    margin-left: 60px;
    margin-right: 4px;
    margin-top: 8px;
  }
}

.tip2 {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #3d7fff;
  line-height: 22px;
  margin-top: 20px;
  margin-left: 4px;
}
.tip1 {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-top: 20px;
  margin-left: 4px;
}
.circle {
  width: 6px;
  height: 6px;
  background: #29ce42;
  border-radius: 50%;
  margin-top: 20px;
  margin-right: 4px;
}
.devider {
  height: 1px;
  border: 1px #e8eaea;
  background: #e8eaea;
}
.content-checkdata {
  align-items: center;
  margin: 0 auto;
  width: 1240px;
  // height: 722px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(17, 31, 65, 0.1);
  // border-radius: 6px;
  border-radius: 0px 0px 6px 6px;

  .company-info-tip {
    height: 22px;
    font-size: 12px;
    font-weight: 400;
    color: #1f1f1f;
    line-height: 22px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;
  }

  .company-info-item {
    margin-top: 20px;
    align-items: center;
    display: flex;
    align-items: center;
    .label {
      margin-right: 16px;
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .el-select {
      width: 150px;
    }
  }
  .table {
    margin-top: 24px;
    margin-left: 20px;
    margin-right: 20px;

    /deep/ .el-table {
      .el-table__body-wrapper {
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(61, 127, 255, 0.4);
          border-radius: 4px;
        }
      }

    }
  }

  .footer-btn {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 20px 0 14px;

    div {
      margin-left: 40px;

      &:first-child {
        margin-left: 0px;
      }
    }
  }
}

</style>
