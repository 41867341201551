<template>
  <!-- v-loading="!state.isDataReady" :element-loading-text="state.loadText" v-loading.fullscreen.lock="true" -->
  <div class="content">
    <div class="content-header flex align-center">
      <div class="logo-top-bar">
        <img
          alt="柠檬云"
          src="@/assets/logo-menu-top.png"
          class="img-ning"
          style="width: 121px"
        />
      </div>

      <div class="headddd">
        <div class="companyName">{{ $store.getters.store_companyName }}</div>
        <div class="content-headler-right flex justify-end align-center">
          <div v-if="!isFromCst" class="flex justify-end align-center">
            <div @mouseover="gwInfo = true" @mouseout="gwInfo = false">
              <div class="header-button" @click="toGW">
                <img
                  v-if="!gwInfo"
                  alt="官网首页"
                  class="header-button-icon"
                  src="@/assets/salary-home.png"
                />
                <img
                  v-else
                  alt="官网首页"
                  class="header-button-icon"
                  src="@/assets/salary-home-click.png"
                />
                官网首页
              </div>
            </div>
            <div @mouseover="studyInfo = true" @mouseout="studyInfo = false">
              <div class="header-button" @click="goNmkjxy">
                <img
                  v-if="!studyInfo"
                  alt="学习中心"
                  class="header-button-icon"
                  src="@/assets/salary-study.png"
                />
                <img
                  v-else
                  alt="学习中心"
                  class="header-button-icon"
                  src="@/assets/salary-study-click.png"
                />
                学习中心
              </div>
            </div>
          </div>
          <div @mouseover="helpInfo = true" @mouseout="helpInfo = false">
            <div class="header-button" @click="help">
              <img
                v-if="!helpInfo"
                alt="帮助中心"
                class="header-button-icon"
                src="@/assets/salary-help.png"
              />
              <img
                v-else
                alt="帮助中心"
                class="header-button-icon"
                src="@/assets/salary-help-click.png"
              />
              帮助中心
            </div>
          </div>
          <div class="divder"></div>
          <!-- <div class="user-name">{{$store.state.userInfo.UserName}}</div> -->
          <div
            class="lemon-user"
            @mouseover="showUserInfo = true"
            @mouseout="showUserInfo = false"
          >
            <img
              class="user-img"
              style="margin-right: 8px"
              :src="'data:image/png;base64, ' + headerImg"
            />
            <!-- $store.state.userInfo.UserName -->
            <span style="line-height: 30px; font-size: 14px" class="float-r">{{
              isShowUserName() ? cstUserName : ''
            }}</span>
            <div
              v-show="showUserInfo"
              style="
                position: absolute;
                right: 0;
                top: 2px;
                width: 120px;
                height: 110px;
                padding-top: 10px;
                z-index: 9999;
              "
            >
              <div class="personal-set">
                <router-link
                  v-if="$store.getters.store_comanyId && !isFromCst"
                  to="personalseting"
                  class="zhiHongSet"
                  >个人设置</router-link
                >
                <span
                  v-if="$store.getters.store_comanyId"
                  class="zhiHongSet"
                  @click="gotoCompanyList"
                  >切换公司</span
                >
                <span v-if="!isFromCst" class="zhiHongSet" @click="exit">退出</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <comm-menu></comm-menu>

    <div class="common-padding">
      <router-view></router-view>
    </div>
    <div class="right" v-if="isshow">
      <div class="right-side">
        <el-popover
          placement="left-start"
          widt="150"
          :close-delay="0"
          popper-class="no-transition"
          trigger="hover"
        >
          <div>
            <img style="width: 150px" src="./assets/slide-image/1.png" alt="" />
          </div>
          <div
            slot="reference"
            class="right-item"
            style="border-radius: 8px 0px 0px 0px; border: 1px solid #e8e8e8"
          >
            <div class="right-item-image">
              <img
                class="service-img"
                src="@/assets/salary-frist-service.png"
              />
            </div>
            <div class="right-item-title">在线客服</div>
          </div>
        </el-popover>
        <el-popover
          v-if="!isFromCst"
          placement="left-start"
          popper-class="no-transition"
          :close-delay="0"
          widt="150"
          trigger="hover"
        >
          <div>
            <img style="width: 150px" src="./assets/slide-image/2.png" alt="" />
          </div>
          <div slot="reference" class="right-item">
            <div class="right-item-image">
              <img
                src="@/assets/salary-frist-qunchat.png"
                style="width: 36px; height: 24px"
              />
            </div>
            <div class="right-item-title">加群交流</div>
          </div>
        </el-popover>
        <el-popover
          placement="left-start"
          popper-class="no-transition"
          widt="150"
          :close-delay="0"
          trigger="hover"
        >
          <div class="last-slide-image-container">
            <img v-if="!$store.state.isCstGz" style="width: 100%" src="./assets/slide-image/3.jpg" alt="" />
            <img v-else style="width: 100%" src="./assets/slide-image/cstQrcode.png" alt="" />
          </div>
          <div slot="reference" class="right-item">
            <div class="right-item-image">
              <img
                style="width: 32px; height: 27px"
                src="@/assets/salary-frist-attentwe.png"
              />
            </div>
            <div class="right-item-title">关注我们</div>
          </div>
        </el-popover>
        <div class="div-hide">
          <el-button
            type="primary"
            size="medium"
            class="button-hide"
            style="border-radius: 0px 0px 0px 8px"
            @click="hide"
            >隐藏 ></el-button
          >
        </div>
      </div>
    </div>
    <div v-if="!isshow" class="right1" @click="hide">
      <div class="right-text">在 线 客 服</div>
      <img
        src="@/assets/zhankai.png"
        style="width: 11px; height: 12px; margin-top: 10px"
      />
    </div>

    <div class="ie-client" v-if="myBrowser() == 'IE' && showIeTip">
      <div class="text1">您当前使用的浏览器内核版本过低！</div>
      <div class="text2">建议您使用360浏览器或谷歌浏览器。</div>
      <div class="img-item">
        <div class="client-item">
          <img src="@/assets/ietip/360.png" />
          <div @click="gotoOther(1)">下载360浏览器</div>
        </div>

        <div class="client-item">
          <img src="@/assets/ietip/chrom.png" />
          <div @click="gotoOther(2)">下载谷歌浏览器</div>
        </div>
      </div>

      <div class="close">
        <img src="@/assets/ietip/close.png" />
        <img class="hover" src="@/assets/ietip/close-hover.png" />
        <div @click="showIeTip = false">关闭</div>
      </div>
    </div>
    <!-- 弹窗广告 -->
    <div class="ad-wrapper" v-if="$store.state.adVisible">
      <div>
        <img :src="$store.state.adInfo.AdUrl" alt="" class="ad-img" @click="jumpToAdLink()"/>
        <div class="close-wrapper">
          <img src="@/assets/homepage/closeWhite.png" alt="" @click="closeAd()" class="close-btn"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import AlertTypeOne from "./components/common/alert-type-1";
// import AlertTypeTwo from "./components/common/alert-type-2";
import helpclick from "./assets/salary-help-click.png";
import helps from "./assets/salary-help.png";
import { environment } from "@/environment";
import { mapState } from "vuex";
import { getValidCache, isCstGzt } from "./common/utils.js";
export default {
  name: "App",
  components: {
    // AlertTypeOne,
    // AlertTypeTwo
  },
  provide() {
    return {
      showTips1: this.showTips1,
      showTips2: this.showTips2,
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      isHaveCompany: false,
      gwInfo: false,
      studyInfo: false,
      helpInfo: false,
      helpclick: helpclick,
      helps: helps,
      showTips: false,
      tipText: "",
      showTips3: false,
      tipText3: "测试",
      selectedAsId: -1,
      AsTitle: "",
      currentAs: {},
      showUserInfo: false,
      isceshi: true,
      headerImg: "",
      menu: [],
      isBellIconHovered: false,
      hoverMenuIndex: -1,
      selectedView: {},
      code: "",
      state: {
        isDataReady: false,
        loadText: "正在加载账套数据...",
      },
      browser: {
        //浏览器类型
        Browser: "",
        //主版本
        Version: 0,
        //操作系统
        OS: 0,
        //分辨率
        ScreenResolution: "",
        //IP编号
        ID: -1,
      },
      isshow: true,
      showIeTip: true,
      isFromCst: false,
      cstUserName: '',
      adSrc: ''
    };
  },
  beforeCreate(){
    function getExecStrs (str) {
      var reg = /(&|\?)cstId=(\d+)&?/g
      var ret = reg.exec(str)
      
      return ret && ret[2]
    }

    // 获取cstId
    let cstId = sessionStorage.getItem('cstId')
    if (cstId && isCstGzt()) {
      let curCstId = getExecStrs(window.location.href)
      if(curCstId){
        sessionStorage.setItem('cstId', Number(curCstId))
      }
      sessionStorage.setItem('cstGzt','true')
    } else {
      //根据输入的URL判断是否来源于财税通
      // 如果url中包含“&cstId=”则视为财税通工资条
      let cstId = getExecStrs(window.location.href)
      cstId && sessionStorage.setItem('cstId', Number(cstId))
      if(cstId &&  isCstGzt()){
        sessionStorage.setItem('cstGzt','true')
      }
    }
  },
  created() {
    this.isFromCst = sessionStorage.getItem('cstGzt')==='true';
    this.$store.commit('$setCstGz', this.isFromCst)
    this.getUser();
    if (
      location.host.includes("localhost:8080") ||
      location.host.includes("test")
    ) {
      this.isceshi = true;
    } else {
      this.isceshi = false;
    }

    // 获取公司列表
    this.companyList();

    this.$store.dispatch("store_setIsTest", this.isceshi);
    if(this.isFromCst){
      this.getCstUserInfo();
    }
  },
  methods: {
    //点击广告弹窗跳转
    jumpToAdLink(){
      let adInfo = this.$store.state.adInfo;
      if(!adInfo.AdLink) return;
      window.open(adInfo.AdLink);
      // 广告点击统计
      this.$axios.jzApi.clickAds(adInfo.AdId).then((res) => {
        // console.log(res)
      })
    },
    //关闭广告
    closeAd(){
      this.$store.commit('$setAdVisible', false)
    },
    isShowUserName(){
      return isCstGzt()
    },
    //获取财税通用户姓名
    getCstUserInfo(){
      this.$axios.cstApi.CstUserInfo(Number(sessionStorage.getItem('cstId'))).then(res => {
        let result = res.data;
        if(result.code === 1000){
          this.cstUserName = result.data.userName;
        }else{
          this.$message.error(result.msg || '获取用户名失败');
        }
      })
    },
    hide() {
      this.isshow = !this.isshow;
    },
    help() {
      window.open(
        "https://file.ningmengyun.com/common/%E6%9F%A0%E6%AA%AC%E4%BA%91%E5%B7%A5%E8%B5%84%E6%9D%A1%E6%93%8D%E4%BD%9C%E6%89%8B%E5%86%8C.pdf"
      );
    },
    goNmkjxy() {
      window.open(environment.stud);
    },
    toGW() {
      window.open(environment.gw);
    },
    showTips1(tipText) {
      this.showTips = true;
      this.tipText = tipText;
    },
    showTips2(tipText) {
      this.showTips3 = true;
      this.tipText3 = tipText;
    },
    gotoCompanyList() {
      this.$router.push({
        path: "/salary/companylist",
      });
    },
    exit() {
      var self = this;
      self.$axios.j2Api
        .logout()
        .then(() => {
          sessionStorage.clear();
          if (isCstGzt()) {
            location.href = environment.cstLogin+"cst/default.aspx";
          } else {
            location.href = environment.gw + "salary.aspx";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUser() {
      //问小亮，userSN=0
      this.$axios.authApi
        .UserInfoGet()
        .then((res) => {
          var data = res.data;
          this.$store.commit("$setUserInfo", data);
          this.headerImg = data.ExtraData.HeadImg;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //获取管理公司列表
    companyList() {
      this.getCompanyList().then((list) => {
        if (!list.length) {
          // 新建公司，取消左侧菜单交互事件
          this.$store.commit("$setMenuActive", true);
          this.$router.push({ path: "/salary/companyinfo" });
          return;
        }
        //判断localStorage中是否包含companyId，companyName；
        // 如果有遍历公司列表，判断该缓存企业是否还存在
        let { hasValidCache, companyId, companyName } = getValidCache(list);
        this.$store.dispatch(
          "store_SetCompanyId",
          hasValidCache ? companyId : list.length>0 ? list[0].companyId : 0
        );
        this.$store.dispatch(
          "store_SetCompanyName",
          hasValidCache ? companyName : list.length>0 ? list[0].companyName : '当前没有公司'
        );
      });
    },

    gotoset() {
      this.$router.push({
        path: "/salary/personalseting",
      });
    },

    gotoOther(type = 1) {
      if (type == 1) {
        window.open("https://browser.360.cn/ee/?appasid=lJao6#");
      } else {
        window.open("https://www.google.cn/chrome/?appasid=lJao6#");
      }
    },
  },
};
</script>

<style lang="scss">
@import "~element-ui/lib/theme-chalk/index.css";
@import "styles";
@import "styles/common.scss";

.last-slide-image-container {
  width: 150px;
  height: 165px;
  box-sizing: border-box;
}

.right1 {
  width: 30px;
  height: 130px;
  background: #3d7fff;
  border-radius: 8px 0px 0px 8px;
  top: 40%;
  right: 7px;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .right-text {
    width: 15px;
    height: 72px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
  }
}
.right {
  width: 8%;
  background: #f8f8f8;
  display: flex;
  position: fixed;
  top: 30%;
  background: transparent;
  right: 7px;
  justify-content: flex-end;
  z-index: 9;
}
.right-side {
  width: 79px;
  // height: 272px;
  background: #ffffff;
  border-radius: 8px 8px 0px 0px;
  .right-item {
    height: 79px;
    border: 1px solid #e8e8e8;
    border-radius: 0px;
    width: 79px;
    cursor: pointer;
  }
  .right-item-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    .service-img {
      width: 34px;
      height: 34px;
    }
  }
  .right-item-title {
    height: 14px;
    font-size: 10px;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .div-hide {
    width: 81px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    cursor: pointer;
    .button-hide {
      width: 81px;
      height: 35px;
      background: #3d7fff;
      border-radius: 1px;
    }
  }
}

.headddd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 150px);
}
.companyName {
  height: 21px;
  font-size: 15px;
  font-weight: 500;
  color: #333333;
  line-height: 21px;
  margin-left: 20px;
}
.personal-set {
  position: absolute;
  right: 0;
  top: 50px;
  width: 120px;
  /* height: 132px; */
  background: #fff;
  z-index: 9999;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.zhiHongSet {
  display: block;
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: #0d0d0d;
}
.zhiHongSet:hover {
  color: #3d7fff;
  background: #f4f7fa;
}

.tools-area {
  display: flex;
  align-items: center;
  font-size: 14px;
  float: right;
}

.lemon-user {
  position: relative;
  // height: 30px;
  display: flex;
  cursor: pointer;
  color: #333;
  font-weight: 400;
  padding: 10px 0;
  margin-right: 16px;
}
.logo-top-bar {
  background: #001529;
  height: 50px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #ccc;
}

.divder {
  height: 18px;
  width: 1px;
  // border: 1px solid #d9dbe2;
  background: #999999;
}

.salary {
}

.aside {
  width: 150px;
  min-height: 100%;
}

.content {
  width: 100%;
  height: 100%;
  background: #eff2f7;
  .ad-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    .ad-img{
      max-height: 600px;
      min-height: 200px;
      width: 550px;
      cursor: pointer;
    }
    .close-wrapper{
      text-align: center;
      margin-top: 20px;
      img.close-btn{
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
  .content-header {
    height: 50px;
    line-height: 50px;
    background: #ffffff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    position: fixed;
    width: 100vw;
    left: 0;
    right: 0;
    // border-bottom: 1px solid #ccc;
  }

  .content-headler-right {
    margin-left: auto;
    margin-right: 10px;

    div {
      margin-left: 20px;
    }
  }
  .header-button {
    height: 17px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 17px;
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;

    &:hover {
      color: #3d7fff;
    }
  }
  .user-name{
    height: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 17px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>


<style lang="scss" scoped>
.ie-client {
  position: fixed;
  // display: none;
  right: 14px;
  bottom: 14px;
  background-color: #fff;
  border-radius: 4px;
  width: 273px;
  height: 150px;
  text-align: center;
  z-index: 12;
  box-shadow: 0 0 22px -5px rgba(0, 0, 0, 0.3);

  .text1 {
    padding-top: 18px;
    color: #333;
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
  }

  .text2 {
    margin-top: 1px;
    color: #969696;
    font-size: 11px;
    line-height: 16px;
  }

  .img-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;

    .client-item {
      margin: 0 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        width: 32px;
        display: block;
        margin-bottom: 5px;
      }

      div {
        width: 91px;
        height: 24px;
        box-sizing: border-box;
        border: 1px solid #44b449;
        text-align: center;
        border-radius: 14px;
        color: #44b449;
        font-size: 11px;
        line-height: 22px;
        cursor: pointer;
        font-weight: 500;

        &:hover {
          background-color: #44b449;
          color: #ffffff;
        }
      }
    }
  }

  .close {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 6px;
    bottom: 7px;
    cursor: pointer;
    color: silver;
    font-size: 11px;
    line-height: 16px;

    img {
      width: 7px;
      display: block;
      margin-right: 8px;

      &.hover {
        display: none;
      }
    }

    &:hover {
      color: #44b449;

      img {
        display: none;

        &.hover {
          display: block !important;
        }
      }
    }
  }
}
.header-button-icon {
  margin-bottom: -4px;
  width: 18px;
  height: 18px;
}
</style>
