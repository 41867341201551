import cstApiInstance from '@/axios/interceptor/cst';
import migrateInstance from '../interceptor/gz/migrate-interceptor';
import cstPcApiInstance from '@/axios/interceptor/cst/cstPc.js'

// 获取公司列表篇
function GetCstCompanyList(userSn, token) {
  return cstApiInstance.get(`/api/CstCompany?userSn=${userSn}&token=${token}`);
}

//迁移公司到财税通
function MigrateToCst(params) {
  let cstId = sessionStorage.getItem('cstId')
  return cstApiInstance.post(`/api/CstCompany?cstId=${cstId}`, {ids: params})
}

function CompanySetting(){
  return cstApiInstance.put(`/api/CstCompany?cstId=${sessionStorage.getItem('cstId')}`, {})
}

//获取管理公司列表
function CompanyGet() {
  return migrateInstance.get('/api/Company');
}

//查询财税通用户信息
function CstUserInfo(cstId){
  return cstPcApiInstance.get(`/api/User/CurrentUser?companyId=${cstId}`)
  // return migrateInstance.get(`/api/User/CurrentUser?companyId=${cstId}`)
}

export default {
  GetCstCompanyList,
  MigrateToCst,
  CompanySetting,
  CompanyGet,
  CstUserInfo
}