import { environment } from "../environment";

//判断是否财税通版工资条
export function isCstGzt() {
  return `${window.location.origin}/` === environment.cstFront
  // return `${window.location.origin}/` === environment.cstFront
}

//判断localStorage中是否包含companyId，companyName；
// 如果有遍历公司列表，判断该缓存企业是否还存在
//获取有效缓存
export function getValidCache(list) {
  let companyId = localStorage.getItem("companyId"),
    companyName = localStorage.getItem("companyName"),
    hasValidCache = false;
  if (companyId) {
    list.forEach((company) => {
      if (
        company.companyId == companyId &&
        company.companyName === companyName
      ) {
        //id和name都匹配
        hasValidCache = true;
      } else if (
        company.companyId == companyId &&
        company.companyName !== companyName
      ) {
        //id匹配，name不匹配
        hasValidCache = true;
        //此时使用列表查询中的企业名称
        companyName = company.companyName;
      }
    });
  }
  return {
    companyId,
    companyName,
    hasValidCache
  }
}

//获取当前的其实时间戳
export function dayStart(){
  let now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);
  return now.getTime();
}

// 当天是否显示过广告
export function hasShowAd(){
  let record = localStorage.getItem('adRecord');
  //没有缓存，则直接显示广告
  if(!record) return false;
  return Number(record) > dayStart();
}

//获取指定日期的年份
export function getYear(timestamp){
  if(!timestamp) return;
  let date = new Date(timestamp);
  return date.getFullYear();
}