import Vue from 'vue'
import App from './App.vue'
import {initPlugin} from '@/plugin';
import router from './router';
import $axios from './axios';
import GlobalComponentInit from './global-component-init';
import GlobalCOmponeDataInit from './global-filter-init';
import * as echarts from 'echarts';
import store from './store/index';
import method from './scripts/methods';

import mixIns from './mixins';
import {environment} from '@/environment';


Vue.config.productionTip = false;
Vue.prototype.$axios = $axios;
Vue.prototype.$echarts = echarts;

Vue.use(method);
initPlugin(Vue);
GlobalComponentInit();
GlobalCOmponeDataInit();

Vue.use(mixIns);

const managerPcApp = new Vue({
  store,
  router,
  render: h => h(App),
});

if(screen.availWidth <= 750) {
  location.href = environment.gw + 'salary/landingpage.html';
} else {
  managerPcApp.$mount('#app');
}
