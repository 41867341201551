import axios from 'axios';
import axiosRetry from 'axios-retry';
import { environment } from '@/environment';

const cstApiInstance = axios.create({
  baseURL: environment.cstApi,
  timeout: 30000,
});

let pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let cancelToken = axios.CancelToken;
let removePending = config => {
  for (let p in pending) {
    if (pending[p].u === config.url + '&' + config.method + '&' + config.data) { //当当前请求在数组中存在时执行函数体
      pending[p].f('重复请求'); //执行取消操作
      pending.splice(p, 1); //把这条记录从数组中移除
    }
  }
}

axiosRetry(cstApiInstance, { retry: 3 });


cstApiInstance.interceptors.request
  .use(config => {
    config.withCredentials = true;
    
    return config;
  });

cstApiInstance.interceptors.request.use(config => {
  removePending(config);
  config.cancelToken = new cancelToken((c) => {
    pending.push({ u: config.url + '&' + config.method + '&' + config.data, f: c });
  });
  return config;
}, error => {
  return Promise.reject(error)
})

cstApiInstance.interceptors.response.use(res => {
  removePending(res.config);
  return res;
}, e => {
  // console.dir(e)
  if (e.response && e.response.status === 401 && location.host.indexOf('localhost') === -1) {
    location.href = environment.gwApi + "salary.aspx";
  }
  return Promise.reject(e);
});



export default cstApiInstance;
