  <template>
  <div class="lemon-pager">
    <div
      class="infos"
      v-if="total>0"
    >显示{{pageSize*pageIndex+1}}-{{Math.min(pageSize*(pageIndex+1),total)}},共{{total}}条记录</div>
    <div class="infos" v-else>共0条数据</div>
    <div class="actions">
      <el-select popper-class="select-common-color" class="pager-select no-height" style="width:90px;" v-model="pageSize" placeholder="请选择">
        <el-option v-for="item in pageSizeOptions" :key="item" :label="item" :value="item"></el-option>
      </el-select>
      <el-button size="mini" @click="first">
        <svg width="9px" height="10px" viewBox="0 0 9 10">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-986.000000, -2400.000000)" fill="#000000">
              <g transform="translate(145.000000, 2396.000000)">
                <g transform="translate(781.000000, 0.000000)">
                  <path
                    d="M65.5900622,9.8238825 L60.9677824,13.7681992 C60.428715,14.2233333 60,13.9460536 60,13.1509323 L60,4.83859515 C60,4.04872286 60.4329223,3.7662069 60.9677824,4.22657727 L65.5900622,8.17084291 C66.1291295,8.63120051 66.1249223,9.36879949 65.5900622,9.8238825 Z M67.5,4 C68.3284271,4 69,4.67157288 69,5.5 L69,12.5 C69,13.3284271 68.3284271,14 67.5,14 C66.6715729,14 66,13.3284271 66,12.5 L66,5.5 C66,4.67157288 66.6715729,4 67.5,4 Z"
                    id="Combined-Shape"
                    transform="translate(64.500000, 9.000000) scale(-1, 1) translate(-64.500000, -9.000000) "
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </el-button>
      <el-button size="mini" @click="prePage">
        <svg width="6px" height="10px" viewBox="0 0 6 10">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-1120.000000, -574.000000)" fill="#000000">
              <g transform="translate(152.000000, 570.000000)">
                <g transform="translate(883.000000, 0.000000)">
                  <path
                    d="M90.5967909,9.82922375 C91.1322948,9.37332988 91.1365071,8.63441667 90.5967909,8.17323882 L85.9689473,4.22194539 C85.4334434,3.76075475 85,4.04377409 85,4.83505375 L85,13.1622016 C85,13.9587396 85.4292311,14.2365134 85.9689473,13.7805683 L90.5967909,9.82922375 Z"
                    id="Fill-1-Copy"
                    transform="translate(88.000000, 9.000000) scale(-1, 1) translate(-88.000000, -9.000000) "
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </el-button>
      <div style="display:inline-block;" @keydown.enter="goto(index)" class="txt-center">
        <span>第 </span>
        <el-input
          :spellcheck="false"
          size="mini"
          style="width:40px;text-align:center;"
          type="number"
          v-model="index"
        ></el-input>
        <span> 页</span>
        <span style="margin-left:10px">共{{pages}}页</span>
      </div>
      <el-button size="mini" @click="nextPage">
        <svg width="6px" height="10px" viewBox="0 0 6 10">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-1273.000000, -574.000000)" fill="#000000">
              <g transform="translate(152.000000, 570.000000)">
                <g transform="translate(883.000000, 0.000000)">
                  <path
                    d="M243.596791,9.82922375 C244.132295,9.37332988 244.136507,8.63441667 243.596791,8.17323882 L238.968947,4.22194539 C238.433443,3.76075475 238,4.04377409 238,4.83505375 L238,13.1622016 C238,13.9587396 238.429231,14.2365134 238.968947,13.7805683 L243.596791,9.82922375 Z"
                    id="Fill-1"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </el-button>
      <el-button size="mini" @click="last">
        <svg width="9px" height="10px" viewBox="0 0 9 10" style="transform:rotate(180deg)">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-986.000000, -2400.000000)" fill="#000000">
              <g transform="translate(145.000000, 2396.000000)">
                <g transform="translate(781.000000, 0.000000)">
                  <path
                    d="M65.5900622,9.8238825 L60.9677824,13.7681992 C60.428715,14.2233333 60,13.9460536 60,13.1509323 L60,4.83859515 C60,4.04872286 60.4329223,3.7662069 60.9677824,4.22657727 L65.5900622,8.17084291 C66.1291295,8.63120051 66.1249223,9.36879949 65.5900622,9.8238825 Z M67.5,4 C68.3284271,4 69,4.67157288 69,5.5 L69,12.5 C69,13.3284271 68.3284271,14 67.5,14 C66.6715729,14 66,13.3284271 66,12.5 L66,5.5 C66,4.67157288 66.6715729,4 67.5,4 Z"
                    id="Combined-Shape"
                    transform="translate(64.500000, 9.000000) scale(-1, 1) translate(-64.500000, -9.000000) "
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      pageSizeOptions: [300, 500, 1000],
      pageSize: 0,
      index: 1
    };
  },
  created: function() {
    if (this.options && this.options.length > 0) {
      this.pageSizeOptions = this.options;
    }
    if (
      this.defaultPageSize > 0 &&
      this.$inArray(this.defaultPageSize, this.pageSizeOptions)
    )
      this.pageSize = this.defaultPageSize;
    else this.pageSize = this.pageSizeOptions[0];
    this.index = this.pageIndex + 1;
  },
  props: {
    total: Number,
    defaultPageSize: Number,
    pageIndex: [Number, String],
    options: Array
  },
  watch: {
    pageSize: function(value, oldValue) {
      if (oldValue !== 0) this.first();
    },
    defaultPageSize(e) {
      this.pageSize = e || 300;
    },
    pageIndex: function(value) {
      var v = value + 1;
      if (v !== this.index) {
        this.index = v;
      }
    }
  },
  computed: {
    pages: function() {
      var result =
        parseInt(this.total / this.pageSize) +
        (this.total % this.pageSize === 0 ? 0 : 1);
      if (result < this.pageIndex + 1 && result > 0) {
        this.$emit("page-change", result - 1, this.pageSize);
      }
      return result;
    }
  },
  methods: {
    goto: function(index) {
      if (index > 0) {
        this.$emit("page-change", index - 1, this.pageSize);
      }
    },
    prePage: function() {
      if (this.pageIndex + 1 > 1) this.goto(Number(this.pageIndex));
    },
    nextPage: function() {
      if (this.pageSize * (this.pageIndex + 1) < this.total)
        this.goto(Number(this.pageIndex) + 1 + 1);
    },
    first: function() {
      this.goto(1);
    },
    last: function() {
      this.goto(this.pages);
    }
  }
};
</script>

<style scoped lang='scss'>
.txt-center input {
  text-align: center;
}
.lemon-pager {
  line-height: 25px;
  min-height: 25px;
  height: 25px;
  margin: 12px 0 5px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-size: 12px;
}
.lemon-pager .infos {
  padding: 0 10px;
  float: left;
}
.lemon-pager .actions {
  float: right;
  display: flex;
  flex-wrap: nowrap;
}
.lemon-pager .actions > * {
  margin: 0 0 0 5px;
}
.lemon-pager .actions .el-button {
  border: none;
  padding: 7px;
  height: 25px;
  line-height: 10px;
}
/deep/.el-input__inner {
  height: 25px;
}
/deep/.el-select .el-input {
  width: 90px;
}
/deep/.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
/deep/ .m-toolbar {
  margin-bottom: 10px;
  display: flex;
}
/deep/.pager-select .el-input .el-input__inner {
  height: 25px !important;
  line-height: 25px !important;
}
/deep/.el-input__suffix {
  right: 0;
  position: absolute;
  height: 100%;
  right: 5px;
  top: 0;
  text-align: center;
  color: #c0c4cc;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
}

/deep/.input-cus .el-input__suffix {
  right: 7px;
  font-size: 12px;
}
/deep/.el-input--suffix .el-input__inner {
  padding-right: 20px;
}
/deep/.el-input__suffix {
  right: 0;
}
/deep/.el-input--small .el-input__icon,
/deep/.el-select.pager-select i.el-input__icon {
  line-height: 28px !important;
}
/deep/input::-webkit-outer-spin-button,
/deep/input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
</style>
