import Vue from 'vue';

// 全局组件
import formatDate from './filters/date';

export default function () {
  Vue.filter('date', formatDate);
  Vue.filter('ownValidator', (i, {arr, un = false}) => {
    if (typeof i === 'boolean') {
      return i;
    }
    return un ? !arr.includes(i) : arr.includes(i);
  })
}
